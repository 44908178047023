import React, { useState, useEffect } from "react";
import { Container, Row, Col,  Breadcrumb } from "react-bootstrap";
import { useParams } from "react-router-dom";
import "./style.css";
const helpers = require("../helpers/helpers");
const Tema = () => {
  const [contador] = useState(0);
  const { UniqueID } = useParams();
  const [tema, setTema] = useState({});
  const [infoTema, setInfoTema] = useState([]);
  const [docentes, setDocentes] = useState([]);
  useEffect(() => {
    helpers
      .getData("/api/temas/view/" + UniqueID)
      .then((datos_tema) => {
        setTema(datos_tema.data.salida[0]);
        helpers
          .getData("/api/informacion-catalogo/read/" + UniqueID)
          .then((info_tema) => {
            setInfoTema(transformData(info_tema.data.salida));
            helpers
              .getData("/api/temas/read/docentes/" + UniqueID)
              .then((datos_docentes) => {
                setDocentes(datos_docentes.data.salida);
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [contador]);
  function transformData(inputArray) {
    const resultArray = [];
    inputArray.forEach((item) => {
      const existingObject = resultArray.find(
        (obj) => obj.tipo === item.tipo && obj.nombre === item.Nombre
      );
      if (existingObject) {
        existingObject.contenido.push(item.contenido);
      } else {
        resultArray.push({
          tipo: item.tipo,
          nombre: item.Nombre,
          contenido: [item.contenido],
        });
      }
    });
    return resultArray;
  }
  return (
    <Container fluid>
      <Row
        style={{
          backgroundColor: "rgb(230, 230, 230)",
          padding: "2%",
          textAlign: "left",
          borderTop: "5px solid #003188",
          borderBottom: "5px solid #003188",
        }}
      >
        <Col xs={12} md={12} lg={2} style={{ padding: "1%" }}>
          <img src={tema.foto} style={{ width: "100%", borderRadius: "2em" }} />
        </Col>
        <Col xs={12} md={12} lg={10} style={{ padding: "1%" }}>
          <h1>
            <strong>EDUCACIÓN Y FORMACIÓN</strong>
          </h1>
          <h2>
            <strong>TEMA: </strong> {tema.nombre}
          </h2>
          <p style={{ textAlign: "justify" }}>{tema.descripcion}</p>
          {docentes.length == 1 && (
            <p>
              <br />
              <strong>{docentes.length} Facilitador EBG disponible</strong>
            </p>
          )}
          {docentes.length > 1 && (
            <p>
              <br />
              <strong>{docentes.length} Facilitadores EBG disponibles</strong>
            </p>
          )}
          <Container fluid>
            <Row className="mt-5">
              <Breadcrumb className="custom-breadcrumb">
                <Breadcrumb.Item className="breadcrumb-link" href="/home">
                  Inicio
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  className="breadcrumb-link"
                  href="/educacion-y-formacion"
                >
                  Educación y Formación
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  href={"/educacion-y-formacion/catalogo/" + tema.idBiblioteca}
                >
                  CATALOGO: {tema.biblioteca}
                </Breadcrumb.Item>
                <Breadcrumb.Item className="breadcrumb-active" active>
                  TEMA: {tema.nombre}
                </Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Container>
        </Col>
      </Row>
      {infoTema && infoTema.length > 0 && (
        <Row>
          <Container fluid>
            <Row className="mt-5">
              <h1>
                <strong>Información del Tema</strong>
              </h1>
            </Row>
            <Row className="mt-5">
              {infoTema &&
                infoTema.map((info, indice) => (
                  <Col xs={12} md={6} lg={4} key={"info-" + indice}>
                    <h2>{info.nombre}</h2>
                    <Container fluid>
                      {info.contenido.map((contenido, index) => (
                        <Row key={"contenido-" + index}>
                          <p style={{ textAlign: "justify" }}>{contenido}</p>
                        </Row>
                      ))}
                    </Container>
                  </Col>
                ))}
            </Row>
          </Container>
        </Row>
      )}
    </Container>
  );
};

export default Tema;
