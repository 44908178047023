import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Home from "./Components/Inicio/Home";
import About from "./Components/quienes-somos/About";
import Unauthorized from "./Components/Unauthorized/Unauthorized";
import Layout from "./Components/Layout/Layout";
import EducacionFormacion from "./Components/educacion-y-formacion/EducacionFormacion";
import InscribeteEnLinea from "./Components/inscribete-en-linea/InscribeteEnLinea";
import ProximosCongresos from "./Components/proximos-congresos/proximosCongresos";
import Eventos from "./Components/eventos/eventos";
import Calendario from "./Components/calendario/calendario";
import CongresosAnteriores from "./Components/congresos-anteriores/congresosAnteriores";
import Pasarela from "./Components/pasarela-pago/Pasarela";
import Inscripcion from "./Components/inscripciones/Inscripcion";
import InscripcionAsesor from "./Components/inscripciones/InscripcionAsesor";
import InscribeteEnLineaAsesor from "./Components/inscribete-en-linea/InscribeteEnLineaAsesor";
import Cobro from "./Components/cobro-electronico/Cobro";
import Gafete from "./Components/gafete-electronico/Gafete";
import InscripcionDescuento from "./Components/inscripciones/InscripcionDescuento";
import InscripcionLead from "./Components/inscripciones/InscripcionLead";
import ValidarCorreo from "./Components/inscripciones/ValidarCorreo";
import InscripcionCortesia from "./Components/inscripciones/InscripcionCortesia";
import Catalogo from "./Components/educacion-y-formacion/Catalogo";
import Tema from "./Components/educacion-y-formacion/Tema";
import Certificaciones from "./Components/certificaciones/Certificaciones";
function App() {
  return (
    <div className="App" style={{ overflowX: "hidden" }}>
      <Router>
        <Routes>
          <Route estrict path="/" element={<Navigate to="/home" replace />} />
          <Route path="/" element={<Layout />}>
            <Route path="home" element={<Home />} />
            <Route path="quienes-somos" element={<About />} />
            <Route path="certificaciones" element={<Certificaciones />} />
            <Route
              path="educacion-y-formacion"
              element={<EducacionFormacion />}
            />
            <Route path="inscribete-en-linea" element={<InscribeteEnLinea />} />
            <Route path="proximos-congresos" element={<ProximosCongresos />} />
            <Route path="eventos/:tipo" element={<Eventos />} />
            <Route path="calendario" element={<Calendario />} />
            <Route path="pasarela-pago" element={<Pasarela />} />
            <Route
              path="congresos-anteriores"
              element={<CongresosAnteriores />}
            />
            <Route path="pre-inscripcion/:hash" element={<ValidarCorreo />} />
            <Route path="inscripcion/:hash" element={<Inscripcion />} />
            <Route
              path="inscripcion/:hash/:hash_asesor"
              element={<InscripcionAsesor />}
            />
            <Route
              path="cortesia-inscripcion/:hash/:hash_asesor"
              element={<InscripcionCortesia />}
            />
            <Route
              path="lead/inscripciones/:hash/:lead"
              element={<InscripcionLead />}
            />
            <Route
              path="inscripcion/:hash/:hash_asesor/:hash_descuento"
              element={<InscripcionDescuento />}
            />
            <Route
              path="inscribete-en-linea/:hashAsesor"
              element={<InscribeteEnLineaAsesor />}
            />
            <Route path="pago-electronico/:hash" element={<Cobro />} />
            <Route path="mi-gafete-electronico/:hash" element={<Gafete />} />
            <Route
              path="educacion-y-formacion/catalogo/:UniqueID"
              element={<Catalogo />}
            />
            <Route
              path="educacion-y-formacion/tema/:UniqueID"
              element={<Tema />}
            />

            {/*<Route path='coretic' element={<Coretic />} />*/}
          </Route>
          <Route path="*" element={<Unauthorized />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
