const postData = (api, body) => {
  return new Promise((resolve, reject) => {
    // Using Fetch API
    fetch(process.env.REACT_APP_DEFAULT_URL + api, {
      method: "POST",
      body: JSON.stringify(body),
      cache: "no-cache",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          reject({
            mensaje: "ERROR: " + response.statusText,
            codigo: 0,
            status: response.status,
          });
        }
      })
      .then((data) => {
        resolve({ mensaje: "Ok", codigo: 1, data: data });
      })
      .catch((err) => {
        reject({
          mensaje: "ERROR: " + err.message,
          codigo: 2,
        });
      });
  });
};
const getData = (api) => {
  return new Promise((resolve, reject) => {
    // Using Fetch API
    fetch(process.env.REACT_APP_DEFAULT_URL + api, {
      cache: "no-cache",
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          reject({
            mensaje: "ERROR: " + response.statusText,
            codigo: 0,
            status: response.status,
          });
        }
      })
      .then((data) => {
        resolve({ mensaje: "Ok", codigo: 1, data: data });
      })
      .catch((err) => {
        reject({
          mensaje: "ERROR: " + err.message,
          codigo: 2,
        });
      });
  });
};
const getDataAuth = (api, TokenType, Token) => {
  return new Promise((resolve, reject) => {
    // Using Fetch API
    fetch(process.env.REACT_APP_DEFAULT_URL + api, {
      cache: "no-cache",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: TokenType + " " + Token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          reject({
            mensaje: "ERROR: " + response.statusText,
            codigo: 0,
            status: response.status,
          });
        }
      })
      .then((data) => {
        resolve({ mensaje: "Ok", codigo: 1, data: data });
      })
      .catch((err) => {
        reject({
          mensaje: "ERROR: " + err.message,
          codigo: 2,
        });
      });
  });
};

const postDataAuth = (api, TokenType, Token, body) => {
  return new Promise((resolve, reject) => {
    // Using Fetch API
    fetch(process.env.REACT_APP_DEFAULT_URL + api, {
      cache: "no-cache",
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: TokenType + " " + Token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          reject({
            mensaje: "ERROR: " + response.statusText,
            codigo: 0,
            status: response.status,
          });
        }
      })
      .then((data) => {
        resolve({ mensaje: "Ok", codigo: 1, data: data });
      })
      .catch((err) => {
        reject({
          mensaje: "ERROR: " + err.message,
          codigo: 2,
        });
      });
  });
};
const deleteDataAuth = (api, TokenType, Token) => {
  return new Promise((resolve, reject) => {
    // Using Fetch API
    fetch(process.env.REACT_APP_DEFAULT_URL + api, {
      cache: "no-cache",
      method: "DELETE",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: TokenType + " " + Token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          reject({
            mensaje: "ERROR: " + response.statusText,
            codigo: 0,
            status: response.status,
          });
        }
      })
      .then((data) => {
        resolve({ mensaje: "Ok", codigo: 1, data: data });
      })
      .catch((err) => {
        reject({
          mensaje: "ERROR: " + err.message,
          codigo: 2,
        });
      });
  });
};
const patchDataAuth = (api, TokenType, Token, body) => {
  return new Promise((resolve, reject) => {
    // Using Fetch API
    fetch(process.env.REACT_APP_DEFAULT_URL + api, {
      cache: "no-cache",
      method: "PATCH",
      body: JSON.stringify(body),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: TokenType + " " + Token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          reject({
            mensaje: "ERROR: " + response.statusText,
            codigo: 0,
            status: response.status,
          });
        }
      })
      .then((data) => {
        resolve({ mensaje: "Ok", codigo: 1, data: data });
      })
      .catch((err) => {
        reject({
          mensaje: "ERROR: " + err.message,
          codigo: 2,
        });
      });
  });
};
const chunkArray = (array, chunkSize) => {
  if (chunkSize <= 0) {
    return array;
  } else {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  }
};
const writeLog = (
  uri_related,
  operacion,
  banco_id,
  usuario_id,
  tokenType,
  accessToken
) => {
  return new Promise((resolve, reject) => {
    postDataAuth("/api/bitacora", tokenType, accessToken, {
      uri_related,
      operacion,
      banco_id,
      usuario_id,
    })
      .then((data) => {
        console.log(data);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const getBase64 = (file, onLoadCallback) => {
  return new Promise(function (resolve, reject) {
    var reader = new FileReader();
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};
module.exports = {
  postData,
  getDataAuth,
  postDataAuth,
  deleteDataAuth,
  patchDataAuth,
  chunkArray,
  writeLog,
  getData,
  getBase64
};
