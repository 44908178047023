import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Button, Accordion, Card, ListGroup } from 'react-bootstrap';
import './About.css'
import Objetivos from './objetivos';
import Enfoque from './enfoque';
const About = () => {
    const [contador, setContador] = useState(0);
    const [objetivos, setObjetivos] = useState(false);
    const [enfoque, setEnfoque] = useState(false);
    useEffect(() => {

    }, [contador]);
    return (
        <Container fluid>
            <Row style={{ padding: '3%' }}>
                <Col xs={12} md={12} lg={6} style={{ marginTop: '3px' }}>
                    <img src='https://test-escuelabancaria.s3.us-east-2.amazonaws.com/Asset-1.svg' alt='logos' />
                </Col>
                <Col xs={12} md={12} lg={6} style={{ display: 'grid', alignItems: 'center', color: '#003188', borderRadius: '35px', marginTop: '3px' }}>
                    <h1>Nosotros</h1>
                </Col>
            </Row>
            <Row style={{ marginBottom: '50px' }}>
                <Col xs={12} md={12} lg={4}>
                    <img
                        src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/businessman-holding-microphone-at-business-seminar-2021-08-28-16-44-08-utc-1-768x1152.jpg"
                        alt="Second slide"
                        style={{ width: '100%', borderRadius: '10%' }}
                    />
                </Col>
                <Col xs={12} md={12} lg={4} style={{ padding: '3%', display: 'grid', alignItems: 'center' }}>
                    <h3>¿Quiénes somos?</h3>
                    <p style={{ textAlign: 'justify', marginTop: '25px' }} className='font-monse'>
                        La Escuela Bancaria de Guatemala (EBG) es la Marca Educativa de la Asociación Bancaria de Guatemala (ABG).<br /><br />
                        Fue creada en el año 2007 para cumplir con uno de los objetivos principales de la Asociación, de proporcionar al Sector Bancario y Financiero, el Capital Humano competente para desarrollar con calidad sus funciones, de acuerdo al nivel ocupacional asignado o proyectado; contribuyendo a la productividad de la Entidades que conforman dichos sectores y por ende a la competitividad del país y de la región Centroamericana en general.
                    </p>
                    <Button variant='outline-primary' className='btn-acerca' href="https://abg.org.gt/" target="_blank">Acerca de ABG</Button>
                </Col>
                <Col xs={12} md={12} lg={4}>
                    <img
                        src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/vertical-photography-of-woman-having-video-confere-2021-08-27-20-57-14-utc-1-768x1151.jpg"
                        alt="First slide"
                        style={{ width: '100%', borderRadius: '10%' }}
                    />
                </Col>
            </Row>
            <Row className='more' >
                <Col xs={12} md={6} lg={6} style={{ padding: '2%' }}>
                    <img src='https://escuela-bancaria.s3.us-east-2.amazonaws.com/vision.png' style={{ width: '25%', marginBottom: '15px' }} alt='img-about' />
                    <h1 style={{ color: 'white', textAlign: 'left' }}>Visión</h1>
                    <p style={{ color: 'white', textAlign: 'justify' }}>
                        Ser la entidad líder en formación profesional integral, del personal que labora en el Sector Bancario y financiero, y otros sectores relacionados.
                        <br />
                        Ser la entidad reconocida y caracterizada por su credibilidad, local e internacional y por la excelencia en el desarrollo de sus actividades.
                    </p>

                </Col>
                <Col xs={12} md={6} lg={6} style={{ padding: '2%' }}>
                    <img src='https://escuela-bancaria.s3.us-east-2.amazonaws.com/lista-de-verificacion.png' style={{ width: '25%', marginBottom: '15px' }} alt='img-about' />
                    <h1 style={{ color: 'white', textAlign: 'left' }}>Misión</h1>
                    <p style={{ color: 'white', textAlign: 'justify' }}>
                        Contribuir al desarrollo del Potencial Humano, del Sector Bancario y Financiero y otros sectores relacionados del país, en los distintos niveles de competencia, para el desempeño laboral. Diseñando, promoviendo e implementando programas, proyectos y servicios técnicos y de especialización laboral; mediante alianzas estratégicas, nacionales e internacionales.
                    </p>

                </Col>
                <Col xs={12} md={6} lg={6} style={{ padding: '2%' }}>
                    <img src='https://escuela-bancaria.s3.us-east-2.amazonaws.com/team-building.png' style={{ width: '25%', marginBottom: '15px' }} alt='img-about' />
                    <h1 style={{ color: 'white', textAlign: 'left' }}>Propósito principal</h1>
                    <p style={{ color: 'white', textAlign: 'justify' }}>
                        Ofrecer al Sector Bancario y Financiero y otros sectores relacionados, el desarrollo integral de su potencial humano, en un proceso de Educación- Formación Continua, con la competencia laboral para desempeñar con eficiencia, eficacia, principios, valores, calidad en el servicio y responsabilidad; diversas posiciones laborales, según el nivel de competencia correspondiente.
                    </p>

                </Col>
                <Col xs={12} md={6} lg={6} style={{ padding: '2%' }}>
                    <img src='https://escuela-bancaria.s3.us-east-2.amazonaws.com/target+(3).png' style={{ width: '25%', marginBottom: '15px' }} alt='img-about' />
                    <h1 style={{ color: 'white', textAlign: 'left' }}>Objetivo general</h1>
                    <p style={{ color: 'white', textAlign: 'justify' }}>
                        Contribuir al aumento de la empleabilidad de nuestros participantes, en sintonía con las demandas laborales actuales; la competitividad del Sector Bancario y Financiero y por ende del desarrollo económico del país y de la región en general.
                    </p>
                </Col>
                <Col xs={12} md={6} lg={6} style={{ padding: '2%' }}>
                    <Button onClick={() => { setObjetivos(true); setContador(contador + 1); }} variant='outline-light'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>&nbsp;&nbsp;&nbsp;Leer objetivos estratégicos de EBG</Button>
                </Col>
                <Col xs={12} md={6} lg={6} style={{ padding: '2%' }}>
                    <Button onClick={() => { setEnfoque(true); setContador(contador + 1); }} variant='outline-light'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>&nbsp;&nbsp;&nbsp;Leer el nuevo enfoque de la formación - educación de la EBG</Button>
                </Col>
            </Row>
            <Row style={{ backgroundColor: '#E6E6E6', padding: '5%' }}>
                <Col xs={12} md={6} lg={6}>
                    <img src='https://escuela-bancaria.s3.us-east-2.amazonaws.com/businessman-speaking-while-holding-digital-tablet-2021-08-28-16-44-08-utc-1.jpg' style={{ width: '100%' }} alt='antecedentes' />
                </Col>
                <Col xs={12} md={6} lg={6} style={{ display: 'grid', alignItems: 'center' }}>
                    <Row style={{ marginTop: '15px', marginBottom: '15px' }}>
                        <h1>Antecedentes EBG</h1>
                    </Row>
                    <Row>
                        <Accordion defaultActiveKey="0" flush>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Año 2000</Accordion.Header>
                                <Accordion.Body style={{ textAlign: 'justify' }}>
                                    <p>Durante este año da inicio el contacto de la Asociación Bancaria de Guatemala con el INTECAP, para realizar el Proyecto de Formación Profesional, Evaluación y Certificación de las Competencias por Normas de Competencia Laboral, a través del cual se trabajó con varios Bancos del Sistema y la coordinación de ABG, para dicho proyecto. Durante el proyecto se obtuvo los siguientes resultados:</p>
                                    <ul style={{ listStyleType: 'disc' }}>
                                        <li>Diseño del mapa funcional del Sector Bancario.</li>
                                        <li>Elaboración de las Normas Técnicas de Competencia Laboral para las funciones técnicas, principales del Sector.</li>
                                        <li>Diseño y desarrollo del Programa de capacitación por competencia laboral del Técnico Bancario, para ser  operado por INTECAP, en coordinación con ABG.</li>
                                        <li>Elaboración de los Cuadernillos de Evaluación de la  competencia laboral y cuadernillos de práctica para la carrera de Técnico Bancario.</li>
                                        <li>Pruebas de evaluación formativa, para el Técnico Bancario.</li>
                                        <li>Elaboración de los Cuadernillos de Evaluación del desempeño en igual número de funciones laborales para la evaluación de empleados bancarios.</li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Año 2001</Accordion.Header>
                                <Accordion.Body style={{ textAlign: 'justify' }}>
                                    <p>Seguimiento al trabajo realizado por parte de ABG para satisfacción del Sector Bancario, a través del inicio de la Carrera del Técnico Bancario, diseñada en coordinación con INTECAP. ABG, juega un papel importante en este trabajo, ya que gracias a su intervención, el Sector Bancario es el primero que cuenta con un programa de capacitación completo, diseñado de acuerdo a las necesidades del Sector, con la metodología de Competencia Laboral, el cual es resultado del trabajo conjunto entre INTECAP, ABG y Empresas del Sector Bancario y Financiero.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Año 2002</Accordion.Header>
                                <Accordion.Body style={{ textAlign: 'justify' }}>
                                    <p>Se realiza la operación del programas de capacitación del Técnico Bancario en diversos Centros de Capacitación del país, así como la certificación de las competencias del personal que labora en el Sector Bancario y Financiero, no importando en donde hayan adquirido las competencias. A inicio de este mismo año y como parte de las estrategias propuestas por el Equipo Técnico de trabajo del diseño y elaboración del paquete didáctico para el Sector Bancario, por Competencia Laboral, se ve la necesidad del diseño de un Centro práctico para el sector Bancario y Financiero.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Row>
                </Col>
            </Row>
            <Row style={{ textAlign: 'justify', padding: '5%' }}>
                <h1 style={{ textAlign: 'center' }}>Nuestro mercado</h1>
                <br />
                <br />
                <br />
                <p>El mercado, sectores o grupo objetivo al cual están enfocadas las acciones de capacitación, formación profesional o educación continua, que diseña e implementa la Escuela Bancaria de Guatemala EBG, en cumplimiento de su misión, visión y objetivos estratégicos, son:</p>
            </Row>
            <Row style={{ backgroundColor: '#E6E6E6', padding: '5%' }}>
                <Col xs={12} md={12} lg={6} style={{ marginTop: '30px' }}>
                    <Card style={{ width: '100%', display: 'grid', placeItems: 'center', backgroundColor: 'transparent', border: '1px solid transparent' }}>
                        <Card.Img variant="top" src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/open-book.png" alt='img' style={{ width: '50%' }} />
                        <Card.Body>
                            <Card.Title>Sector educativo</Card.Title>
                        </Card.Body>
                        <ListGroup className="list-group-flush" style={{ textAlign: 'justify' }}>
                            <ListGroup.Item style={{ backgroundColor: 'transparent', border: '1px solid transparent' }}>Estudiantes de nivel básico, con vocación en el área de la banca y las finanzas que desean continuar en una Carrera a nivel Diversificado como Perito Contador con Orientación Bancaria, para desarrollar las competencias de conocimiento, destrezas, habilidades, actitudes y experiencias necesarias para aumentar su nivel de empleabilidad e iniciar a laborar en el Sector Bancario, Financiero y otros sectores relacionados.</ListGroup.Item>
                            <ListGroup.Item style={{ backgroundColor: 'transparent', border: '1px solid transparent' }}>Estudiantes del diversificado que deseen obtener una doble titulación, a través de la Certificación en Operaciones Bancarias y Financieras, que les proporcione la oportunidad de aumentar su nivel de empleabilidad para ingresar al mundo laboral de la banca, las finanzas y el sector productivo en general.</ListGroup.Item>
                            <ListGroup.Item style={{ backgroundColor: 'transparent', border: '1px solid transparent' }}>Estudiantes a nivel diversificado que desean continuar con una Carrera Técnica Universitaria con salidas intermedias a nivel Técnico o de Licenciatura.</ListGroup.Item>
                        </ListGroup>
                    </Card>
                </Col>
                <Col xs={12} md={12} lg={6} style={{ marginTop: '30px' }}>
                    <Card style={{ width: '100%', display: 'grid', placeItems: 'center', backgroundColor: 'transparent', border: '1px solid transparent' }}>
                        <Card.Img variant="top" src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/school.png" alt='img' style={{ width: '50%' }} />
                        <Card.Body style={{ height: '100%' }}>
                            <Card.Title>Sector universitario</Card.Title>
                        </Card.Body>
                        <ListGroup className="list-group-flush" style={{ textAlign: 'justify' }}>
                            <ListGroup.Item style={{ backgroundColor: 'transparent', border: '1px solid transparent' }}>Estudiantes de la Carrera Técnica Universitaria en Operaciones Financieras y Bancarias, Licenciatura en Administración Financiera y Bancaria y Maestría en Dirección Financiera y Bancaria, establecida en alianza con Universidades reconocidas en el país o a nivel internacional.</ListGroup.Item>
                        </ListGroup>
                    </Card>
                </Col>
                <Col xs={12} md={12} lg={6} style={{ marginTop: '30px' }}>
                    <Card style={{ width: '100%', display: 'grid', placeItems: 'center', backgroundColor: 'transparent', border: '1px solid transparent' }}>
                        <Card.Img variant="top" src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/bank-building.png" alt='img' style={{ width: '50%' }} />
                        <Card.Body>
                            <Card.Title>Sector bancario y financiero y no financiero</Card.Title>
                        </Card.Body>
                        <ListGroup className="list-group-flush" style={{ textAlign: 'justify' }}>
                            <ListGroup.Item style={{ backgroundColor: 'transparent', border: '1px solid transparent' }}>Colaboradores y Funcionarios de Instituciones Bancarias, Financieras y no financieras, Cooperativas, ONG´s y Empresas Comerciales relacionadas, que desean completar o especializarse en las funciones o posiciones laborales a los 5 niveles de competencia, establecidos para del Sector.</ListGroup.Item>
                            <ListGroup.Item style={{ backgroundColor: 'transparent', border: '1px solid transparent' }}>Personas individuales con interés en participar en los programas de congresos, seminarios, temas técnicos o de desarrollo de las competencias transversales y/o en los programas universitarios, con el fin de iniciarse en el área bancaria o financiera o complementar o llenar brechas identificadas.</ListGroup.Item>
                        </ListGroup>
                    </Card>
                </Col>
                <Col xs={12} md={12} lg={6} style={{ marginTop: '30px' }}>
                    <Card style={{ width: '100%', display: 'grid', placeItems: 'center', backgroundColor: 'transparent', border: '1px solid transparent' }}>
                        <Card.Img variant="top" src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/team.png" alt='img' style={{ width: '50%' }} />
                        <Card.Body>
                            <Card.Title>Sector comercial e industria</Card.Title>
                        </Card.Body>
                        <ListGroup className="list-group-flush" style={{ textAlign: 'justify' }}>
                            <ListGroup.Item style={{ backgroundColor: 'transparent', border: '1px solid transparent' }}>ONG´s y Empresas Comerciales e Industrias relacionadas, que desean formar a sus colaboradores o funcionarios o de llenar brechas detectadas a través de la evaluación del desempeño, en temas financieros, contables, de Auditoría Interna, de Prevención de Lavado de Dinero, Microfinanzas, Sistemas de Tecnología de la Información y Comunicación TIC´s, Seguridad TI, Gobierno Corporativo y Gestión Integral de Riesgos, Comercialización y Venta de Productos o Servicios y otros temas transversales.</ListGroup.Item>
                            <ListGroup.Item style={{ backgroundColor: 'transparent', border: '1px solid transparent' }}>Colaboradores y Funcionarios de Instituciones no financieras, Cooperativas, ONG´s y Empresas Comerciales relacionadas, que desean Evaluar y Certificar las competencias adquiridas en las funciones operativas, administrativas o gerenciales, bajo estándares definidos por el Sector, relacionadas con temas financieros, contables, de Auditoría Interna, de Prevención de Lavado de Dinero, Microfinanzas, Sistemas de Tecnología de la Información y Comunicación TIC´s, Seguridad TI, Gobierno Corporativo y Gestión Integral de Riesgos, Comercialización y Venta de Productos o Servicios y otros temas transversales.</ListGroup.Item>
                        </ListGroup>
                    </Card>
                </Col>
            </Row>
            <Objetivos show={objetivos} onHide={() => { setObjetivos(false); setContador(contador + 1); }} />
            <Enfoque show={enfoque} onHide={() => { setEnfoque(false); setContador(contador + 1); }} />
        </Container>
    )
}

export default About