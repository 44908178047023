import React from "react";
import { useParams } from "react-router-dom";
import Inscripciones from "./Inscripciones";
const InscripcionLead = () => {
  const { hash, lead } = useParams();
  return (
    <Inscripciones
      hash={hash}
      hash_asesor={null}
      hash_descuento={null}
      lead={lead}
      cortesia={false}
    />
  );
};

export default InscripcionLead;
