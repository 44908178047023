import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import './style.css'
const Eventos = () => {
    const { tipo } = useParams();
    const [contador] = useState(0);
    const [cartera, setCartera] = useState([]);
    const [imgAgenda, setImgAgenda] = useState('');
    const [titulo, setTitulo] = useState('');
    useEffect(() => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/inscripciones/abiertas', { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    let tmp = [];
                    data.rows.map((actual, index) => {
                        if (index === 0) {
                            tmp.push({ tipo: actual.tipo, tipo_id: actual.tipo_id, eventos: [actual] });
                        } else {
                            const index_finder = (tipo) => tipo.tipo_id === actual.tipo_id;
                            if (tmp.findIndex(index_finder) >= 0) {
                                let temp = tmp[tmp.findIndex(index_finder)];
                                temp.eventos.push(actual);
                                tmp[tmp.findIndex(index_finder)] = temp;
                            } else {
                                tmp.push({ tipo: actual.tipo, tipo_id: actual.tipo_id, eventos: [actual] });
                            }
                            if (index === (data.rows.length - 1)) {
                                setCartera(tmp);
                                console.log(tmp);
                            }
                        }
                        return true;
                    });
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/agenda/info', { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setTitulo(data.titulo);
                    setImgAgenda(data.imagen);
                });
        }
        fetchData();
    }, [contador])

    return (
        <Container fluid>
            <Row style={{ backgroundColor: 'rgb(230, 230, 230)', padding: '2%', textAlign: 'left', borderTop: '5px solid #003188', borderBottom: '5px solid #003188' }}>
                <Col xs={12} md={12} lg={2} style={{ padding: '1%' }}>
                    <img src={imgAgenda} style={{ width: '100%', borderRadius: '2em' }} />
                </Col>
                <Col xs={12} md={12} lg={10} style={{ padding: '1%' }}>
                    <h1><strong>{tipo}</strong></h1>
                    <h3><strong>{titulo}</strong></h3>
                </Col>
            </Row>
            {
                cartera && cartera.map((tipo_evt, indice) =>
                    <Row key={indice}>
                        {
                            tipo_evt.tipo === tipo && <Container >
                                {
                                    tipo_evt.eventos.map((evento, index) => <Row style={{ borderBottom: '1px solid rgb(230, 230, 230)', padding: '2%' }}>
                                        <Col xs={12} md={12} lg={4} style={{ marginTop: '25px', borderRight: '1px solid black', textAlign: 'right', padding: '2%' }}>
                                            <h1 style={{ textShadow: '11px -15px 2px rgba(230, 230, 230, 1)' }}>{evento.fechaInicioMes}</h1>
                                        </Col>
                                        <Col xs={12} md={12} lg={4} style={{ marginTop: '25px', textAlign: 'left', padding: '2%' }}>
                                            <h3><strong>{evento.Nombre}</strong></h3>
                                            <p><strong>Días a impartir: </strong>{evento.dias}<br /><strong>Duración: </strong>{evento.duracion}<br /><strong>Sesiones: </strong>{evento.sesiones}<br /><strong>Hora: </strong>{evento.Hora}</p>
                                            {evento.link_alternativo == null &&<Button variant='outline-secondary' href={'/inscripcion/' + evento.hash}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-plus-fill" viewBox="0 0 16 16">
                                                <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                                <path fillRule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z" />
                                            </svg>&nbsp;&nbsp;&nbsp;&nbsp;Inscribirme en línea</Button>}
                                            {evento.link_alternativo != null &&<Button variant='outline-secondary' href={evento.link_alternativo} target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-plus-fill" viewBox="0 0 16 16">
                                                <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                                <path fillRule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z" />
                                            </svg>&nbsp;&nbsp;&nbsp;&nbsp;Inscribirme en línea</Button>}
                                        </Col>
                                        <Col xs={12} md={12} lg={4} style={{ marginTop: '25px', textAlign: 'left', padding: '2%' }}>
                                            <img src={evento.img} style={{ width: '100%', borderRadius: '5em' }} />
                                        </Col>
                                    </Row>)
                                }
                            </Container>
                        }
                    </Row>
                )
            }
        </Container>
    )
}

export default Eventos