import React from 'react'
import { Modal, Container, Row, Col } from 'react-bootstrap'
import './style.css';
const ModalEnlaces = (props) => {
    return (
        <Modal
            {...props}
            size="xs"
            id='modal-mas'
        >
            <Modal.Header>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <Row style={{ display: 'grid', placeItems: 'center' }}>
                        <img src='https://escuela-bancaria.s3.us-east-2.amazonaws.com/add-user+(1).png' onClick={() => { window.location.replace('/inscribete-en-linea') }} style={{ width: '70px', cursor: 'pointer' }} alt='inscribete en línea' />
                    </Row>
                    <Row>
                        <p style = {{textAlign:'center'}}>Inscribete en línea</p>
                    </Row>
                    <Row style={{ display: 'grid', placeItems: 'center' }}>
                        <img onClick={() => { window.open("https://wa.me/+50223827200", "_blank"); }} src="https://test-escuelabancaria.s3.us-east-2.amazonaws.com/Medios+General/whatsapp.png" style={{ width: '70px', cursor: 'pointer' }} alt="whatsapp-icon" />
                        <p style = {{textAlign:'center'}}> Envianos un whatsapp</p>
                    </Row>
                </Container>

            </Modal.Body>
        </Modal>)
}

export default ModalEnlaces