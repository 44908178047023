import React from 'react'
import { Container, Row, Col, Carousel, Button } from 'react-bootstrap';
const Patrocinadores = (props) => {
    const marcar_stand = (id) => {
        const info = { hash: props.participante, id: id };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/create/evento/marcar/asistencia/stand', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo == 200) {
                    window.location.reload();
                } else {

                }
            });
    }
    return (
        <Container fluid>
            <Row style={{ textAlign: 'left', padding: '2%', borderTop: '5px solid #003188', borderBottom: '5px solid #003188', backgroundColor: 'rgb(230, 230, 230)' }}>
                <h1><strong>PATROCINADORES, MEDIA PARTNERS Y APOYOS INSTITUCIONALES</strong></h1>
            </Row>
            {props.patrocinadores.map((actual, index) => <Row key={'tipo-patrocinio' + index} style={{ padding: '2%', textAlign: 'left' }}>
                <Col xs={12} md={3} lg={3} style={{ marginTop: '25px', display: 'flex', placeItems: 'center', paddingTop: '2%', paddingBottom: '2%', paddingRight: '5%' }}>
                    <h2>{actual.tipo}</h2>
                </Col>
                <Col xs={12} md={9} lg={9} style={{ textAlign: 'left', marginTop: '25px', paddingTop: '2%', paddingBottom: '2%', paddingLeft: '10%', borderLeft: '2px solid #003188' }}>
                    <Carousel variant='dark' controls={false}>
                        {actual.patrocinadores.map((curr, indice) => <Carousel.Item interval={2500} key={'patrocinador-' + index + '-' + curr.UniqueID}>
                            <Container style={{ padding: '5%' }}>
                                <Row>
                                    <Col xs={4} md={4} lg={4}>
                                        <img src={curr.promocional_landing} style={{ width: '100px', height: '100px', objectFit: 'contain' }} />
                                    </Col>
                                    <Col xs={8} md={8} lg={8}>
                                        <h3>{curr.nombre}</h3>
                                        <Button variant='outline-dark' href={curr.url} target="_blank" style={{ marginTop: '5px' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-right" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
                                            <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                                        </svg>&nbsp;&nbsp;&nbsp;&nbsp;Visitar sitio web</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <Button disabled={curr.visitado} onClick={() => { marcar_stand(curr.UniqueID); }} variant='outline-dark' style={{ marginTop: '5px' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                        </svg>&nbsp;&nbsp;&nbsp;&nbsp;Marcar visita stand</Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Carousel.Item>)}
                    </Carousel>
                </Col>
            </Row>)}
        </Container>
    )
}

export default Patrocinadores