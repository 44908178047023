import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import CargaMasiva from "./CargaMasiva";
import PasarelaForm from "./PasarelaForm";
import Jornadas from "./Jornadas";
import Patrocinadores from "./Patrocinadores";
import Temario from "./Temario";
import axios from "axios";
import "./style.css";
const Inscripciones = (props) => {
  const [contador] = useState();
  const [evento, setEvento] = useState({});
  const [tarifario, setTarifario] = useState([]);
  const [formasPago, setFormasPago] = useState([]);
  const [asesores, setAsesores] = useState([]);
  const [agremiados, setAgremiados] = useState(false);
  const [institucionesAgremiadas, setInstitucionesAgremiadas] = useState([]);
  const [paises, setPaises] = useState([]);
  const [participantes, setParticipantes] = useState([
    {
      Nombres: "",
      Apellidos: "",
      Puesto: "",
      Correo: "",
      Tel: "",
      Celular: "",
      Pais: "",
    },
  ]);
  const [fechaMax, setFechaMax] = useState("");
  const [validated, setValidated] = useState(false);
  const [cambios, setCambios] = useState(0);
  const [institucion, setInstitucion] = useState("");
  const [nit, setNit] = useState("");
  const [nombreFactura, setNombreFactura] = useState("");
  const [direccion, setDireccion] = useState("");
  const [correoFactura, setCorreoFactura] = useState("");
  const [formaPago, setFormaPago] = useState("");
  const [comentario, setComentario] = useState("");
  const [cargando, setCargando] = useState("none");
  const [exito, setExito] = useState("none");
  const [error, setError] = useState("none");
  const [mensaje, setMensaje] = useState("");
  const [asesor, setAsesor] = useState(0);
  const [inversion, setInversion] = useState(0);
  const [pasarela, setPasarela] = useState("none");
  const [idInscripcion, setIdInscripcion] = useState("");
  const [imgAgenda, setImgAgenda] = useState("");
  const [titulo, setTitulo] = useState("");
  const [jornadas, setJornadas] = useState([]);
  const [patrocinadores, setPatrocinadores] = useState([]);
  const [ejes, setEjes] = useState([]);
  const [descuento, setDescuento] = useState({});
  const [comprobante, setComprobante] = useState(null);
  const [lead, setLead] = useState({});
  const [sesiones, setSesiones] = useState([]);
  useEffect(() => {
    fetch(
      process.env.REACT_APP_DEFAULT_URL +
        "/api/read/evento/landing/" +
        props.hash,
      { cache: "no-cache" }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.rows[0].disponible == "0") {
          window.location.replace("/unauthorized");
        } else {
          let idEvt = data.rows[0].UniqueID;
          setEvento(data.rows[0]);
          if (
            data.rows[0].costo_agremiados == 0 ||
            data.rows[0].costo_no_agremiados == 0 ||
            props.cortesia
          ) {
            setFechaMax(
              new Date(Date.now()).getFullYear() +
                "-" +
                (new Date(Date.now()).getMonth() + 1) +
                "-" +
                new Date(Date.now()).getDate()
            );
          }
          if (props.hash_descuento != null) {
            fetch(
              process.env.REACT_APP_DEFAULT_URL +
                "/api/descuentos/read/" +
                props.hash +
                "/" +
                props.hash_asesor +
                "/" +
                props.hash_descuento,
              { cache: "no-cache" }
            )
              .then((response_descuento) => {
                return response_descuento.json();
              })
              .then((data_descuento) => {
                if (
                  data_descuento.rows.length > 0 &&
                  data_descuento.rows[0].dias > 0
                ) {
                  setDescuento(data_descuento.rows[0]);
                  setComentario(data_descuento.rows[0].ret);
                  let inversion_total = 0;
                  if (agremiados) {
                    inversion_total =
                      data.rows[0].costo_agremiados * participantes.length;
                  } else {
                    inversion_total =
                      data.rows[0].costo_no_agremiados * participantes.length;
                  }
                  inversion_total =
                    inversion_total - data_descuento.rows[0].descuento;
                  setInversion(inversion_total);
                } else {
                  let inversion_total = 0;
                  if (agremiados) {
                    inversion_total =
                      data.rows[0].costo_agremiados * participantes.length;
                  } else {
                    inversion_total =
                      data.rows[0].costo_no_agremiados * participantes.length;
                  }
                  setInversion(inversion_total);
                }
              });
          } else {
            let inversion_total = 0;
            if (!props.cortesia) {
              if (agremiados) {
                inversion_total =
                  data.rows[0].costo_agremiados * participantes.length;
              } else {
                inversion_total =
                  data.rows[0].costo_no_agremiados * participantes.length;
              }
            }
            setInversion(inversion_total);
          }
          fetch(
            process.env.REACT_APP_DEFAULT_URL +
              "/api/read/evento/tarifas/" +
              idEvt,
            { cache: "no-cache" }
          )
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              setTarifario(data.salida);
              fetch(
                process.env.REACT_APP_DEFAULT_URL +
                  "/api/jornada/read/" +
                  idEvt,
                { cache: "no-cache" }
              )
                .then((res) => {
                  return res.json();
                })
                .then((data) => {
                  setJornadas(data.rows);
                  fetch(
                    process.env.REACT_APP_DEFAULT_URL +
                      "/api/temario/read/" +
                      idEvt,
                    { cache: "no-cache" }
                  )
                    .then((res) => {
                      return res.json();
                    })
                    .then((data) => {
                      setEjes(data.rows);
                      fetch(
                        process.env.REACT_APP_DEFAULT_URL +
                          "/api/sesion/check/" +
                          idEvt,
                        { cache: "no-cache" }
                      )
                        .then((response) => {
                          return response.json();
                        })
                        .then((datos) => {
                          setSesiones(datos.rows);
                          //console.log(datos.rows);
                          fetch(
                            process.env.REACT_APP_DEFAULT_URL +
                              "/api/read/patrocinador-for-page/" +
                              idEvt,
                            { cache: "no-cache" }
                          )
                            .then((res) => {
                              return res.json();
                            })
                            .then((data) => {
                              setPatrocinadores(data.rows);
                            });
                        });
                    });
                });
            });
        }
      });
    fetch(process.env.REACT_APP_DEFAULT_URL + "/api/read/forma_pago", {
      cache: "no-cache",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setFormasPago(data.rows);
      });
    fetch(process.env.REACT_APP_DEFAULT_URL + "/api/read/asesor", {
      cache: "no-cache",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setAsesores(data.rows);
      });
    fetch(process.env.REACT_APP_DEFAULT_URL + "/api/read/agremiados", {
      cache: "no-cache",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setInstitucionesAgremiadas(data.agremiados);
      });

    setPaises([
      { id: "ad", nombre: "Andorra" },
      { id: "ae", nombre: "Emiratos Árabes Unidos" },
      { id: "af", nombre: "Afganistán" },
      { id: "ag", nombre: "Antigua y Barbuda" },
      { id: "ai", nombre: "Anguila" },
      { id: "al", nombre: "Albania" },
      { id: "am", nombre: "Armenia" },
      { id: "ao", nombre: "Angola" },
      { id: "aq", nombre: "Antártida" },
      { id: "ar", nombre: "Argentina" },
      { id: "as", nombre: "Samoa Americana" },
      { id: "at", nombre: "Austria" },
      { id: "au", nombre: "Australia" },
      { id: "aw", nombre: "Aruba" },
      { id: "ax", nombre: "Åland" },
      { id: "az", nombre: "Azerbaiyán" },
      { id: "ba", nombre: "Bosnia y Herzegovina" },
      { id: "bb", nombre: "Barbados" },
      { id: "bd", nombre: "Bangladesh" },
      { id: "be", nombre: "Bélgica" },
      { id: "bf", nombre: "Burkina Faso" },
      { id: "bg", nombre: "Bulgaria" },
      { id: "bh", nombre: "Baréin" },
      { id: "bi", nombre: "Burundi" },
      { id: "bj", nombre: "Benín" },
      { id: "bl", nombre: "San Bartolomé" },
      { id: "bm", nombre: "Bermudas" },
      { id: "bn", nombre: "Brunéi" },
      { id: "bo", nombre: "Bolivia" },
      { id: "bq", nombre: "Caribe Neerlandés" },
      { id: "br", nombre: "Brasil" },
      { id: "bs", nombre: "Bahamas" },
      { id: "bt", nombre: "Bután" },
      { id: "bv", nombre: "Isla Bouvet" },
      { id: "bw", nombre: "Botsuana" },
      { id: "by", nombre: "Bielorrusia" },
      { id: "bz", nombre: "Belice" },
      { id: "ca", nombre: "Canadá" },
      { id: "cc", nombre: "Islas Cocos" },
      { id: "cd", nombre: "Congo (Rep. Dem.)" },
      { id: "cf", nombre: "República Centroafricana" },
      { id: "cg", nombre: "Congo" },
      { id: "ch", nombre: "Suiza" },
      { id: "ci", nombre: "Costa de Marfil" },
      { id: "ck", nombre: "Islas Cook" },
      { id: "cl", nombre: "Chile" },
      { id: "cm", nombre: "Camerún" },
      { id: "cn", nombre: "China" },
      { id: "co", nombre: "Colombia" },
      { id: "cr", nombre: "Costa Rica" },
      { id: "cu", nombre: "Cuba" },
      { id: "cv", nombre: "Cabo Verde" },
      { id: "cw", nombre: "Curazao" },
      { id: "cx", nombre: "Isla de Navidad" },
      { id: "cy", nombre: "Chipre" },
      { id: "cz", nombre: "República Checa" },
      { id: "de", nombre: "Alemania" },
      { id: "dj", nombre: "Yibuti" },
      { id: "dk", nombre: "Dinamarca" },
      { id: "dm", nombre: "Dominica" },
      { id: "do", nombre: "República Dominicana" },
      { id: "dz", nombre: "Argelia" },
      { id: "ec", nombre: "Ecuador" },
      { id: "ee", nombre: "Estonia" },
      { id: "eg", nombre: "Egipto" },
      { id: "eh", nombre: "Sahara Occidental" },
      { id: "er", nombre: "Eritrea" },
      { id: "es", nombre: "España" },
      { id: "et", nombre: "Etiopía" },
      { id: "eu", nombre: "Unión Europea" },
      { id: "fi", nombre: "Finlandia" },
      { id: "fj", nombre: "Fiyi" },
      { id: "fk", nombre: "Islas Malvinas" },
      { id: "fm", nombre: "Micronesia" },
      { id: "fo", nombre: "Islas Feroe" },
      { id: "fr", nombre: "Francia" },
      { id: "ga", nombre: "Gabón" },
      { id: "gb", nombre: "Reino Unido" },
      { id: "gb-eng", nombre: "Inglaterra" },
      { id: "gb-nir", nombre: "Irlanda del Norte" },
      { id: "gb-sct", nombre: "Escocia" },
      { id: "gb-wls", nombre: "Gales" },
      { id: "gd", nombre: "Granada" },
      { id: "ge", nombre: "Georgia" },
      { id: "gf", nombre: "Guayana Francesa" },
      { id: "gg", nombre: "Guernsey" },
      { id: "gh", nombre: "Ghana" },
      { id: "gi", nombre: "Gibraltar" },
      { id: "gl", nombre: "Groenlandia" },
      { id: "gm", nombre: "Gambia" },
      { id: "gn", nombre: "Guinea" },
      { id: "gp", nombre: "Guadalupe" },
      { id: "gq", nombre: "Guinea Ecuatorial" },
      { id: "gr", nombre: "Grecia" },
      { id: "gs", nombre: "Islas Georgias del Sur y Sándwich del Sur" },
      { id: "gt", nombre: "Guatemala" },
      { id: "gu", nombre: "Guam" },
      { id: "gw", nombre: "Guinea-Bisáu" },
      { id: "gy", nombre: "Guyana" },
      { id: "hk", nombre: "Hong Kong" },
      { id: "hm", nombre: "Islas Heard y McDonald" },
      { id: "hn", nombre: "Honduras" },
      { id: "hr", nombre: "Croacia" },
      { id: "ht", nombre: "Haití" },
      { id: "hu", nombre: "Hungría" },
      { id: "id", nombre: "Indonesia" },
      { id: "ie", nombre: "Irlanda" },
      { id: "il", nombre: "Israel" },
      { id: "im", nombre: "Isla de Man" },
      { id: "in", nombre: "India" },
      { id: "io", nombre: "Territorio Británico del Océano Índico" },
      { id: "iq", nombre: "Irak" },
      { id: "ir", nombre: "Irán" },
      { id: "is", nombre: "Islandia" },
      { id: "it", nombre: "Italia" },
      { id: "je", nombre: "Jersey" },
      { id: "jm", nombre: "Jamaica" },
      { id: "jo", nombre: "Jordania" },
      { id: "jp", nombre: "Japón" },
      { id: "ke", nombre: "Kenia" },
      { id: "kg", nombre: "Kirguistán" },
      { id: "kh", nombre: "Camboya" },
      { id: "ki", nombre: "Kiribati" },
      { id: "km", nombre: "Comoras" },
      { id: "kn", nombre: "San Cristóbal y Nieves" },
      { id: "kp", nombre: "Corea del Norte" },
      { id: "kr", nombre: "Corea del Sur" },
      { id: "kw", nombre: "Kuwait" },
      { id: "ky", nombre: "Islas Caimán" },
      { id: "kz", nombre: "Kazajistán" },
      { id: "la", nombre: "Laos" },
      { id: "lb", nombre: "Líbano" },
      { id: "lc", nombre: "Santa Lucía" },
      { id: "li", nombre: "Liechtenstein" },
      { id: "lk", nombre: "Sri Lanka" },
      { id: "lr", nombre: "Liberia" },
      { id: "ls", nombre: "Lesoto" },
      { id: "lt", nombre: "Lituania" },
      { id: "lu", nombre: "Luxemburgo" },
      { id: "lv", nombre: "Letonia" },
      { id: "ly", nombre: "Libia" },
      { id: "ma", nombre: "Marruecos" },
      { id: "mc", nombre: "Mónaco" },
      { id: "md", nombre: "Moldavia" },
      { id: "me", nombre: "Montenegro" },
      { id: "mf", nombre: "San Martín (Francia)" },
      { id: "mg", nombre: "Madagascar" },
      { id: "mh", nombre: "Islas Marshall" },
      { id: "mk", nombre: "Macedonia del Norte" },
      { id: "ml", nombre: "Malí" },
      { id: "mm", nombre: "Myanmar" },
      { id: "mn", nombre: "Mongolia" },
      { id: "mo", nombre: "Macao" },
      { id: "mp", nombre: "Islas Marianas del Norte" },
      { id: "mq", nombre: "Martinica" },
      { id: "mr", nombre: "Mauritania" },
      { id: "ms", nombre: "Montserrat" },
      { id: "mt", nombre: "Malta" },
      { id: "mu", nombre: "Mauricio" },
      { id: "mv", nombre: "Maldivas" },
      { id: "mw", nombre: "Malawi" },
      { id: "mx", nombre: "México" },
      { id: "my", nombre: "Malasia" },
      { id: "mz", nombre: "Mozambique" },
      { id: "na", nombre: "Namibia" },
      { id: "nc", nombre: "Nueva Caledonia" },
      { id: "ne", nombre: "Níger" },
      { id: "nf", nombre: "Isla Norfolk" },
      { id: "ng", nombre: "Nigeria" },
      { id: "ni", nombre: "Nicaragua" },
      { id: "nl", nombre: "Países Bajos" },
      { id: "no", nombre: "Noruega" },
      { id: "np", nombre: "Nepal" },
      { id: "nr", nombre: "Nauru" },
      { id: "nu", nombre: "Niue" },
      { id: "nz", nombre: "Nueva Zelanda" },
      { id: "om", nombre: "Omán" },
      { id: "pa", nombre: "Panamá" },
      { id: "pe", nombre: "Perú" },
      { id: "pf", nombre: "Polinesia Francesa" },
      { id: "pg", nombre: "Papúa Nueva Guinea" },
      { id: "ph", nombre: "Filipinas" },
      { id: "pk", nombre: "Pakistán" },
      { id: "pl", nombre: "Polonia" },
      { id: "pm", nombre: "San Pedro y Miquelón" },
      { id: "pn", nombre: "Islas Pitcairn" },
      { id: "pr", nombre: "Puerto Rico" },
      { id: "ps", nombre: "Palestina" },
      { id: "pt", nombre: "Portugal" },
      { id: "pw", nombre: "Palaos" },
      { id: "py", nombre: "Paraguay" },
      { id: "qa", nombre: "Catar" },
      { id: "re", nombre: "Reunión" },
      { id: "ro", nombre: "Rumania" },
      { id: "rs", nombre: "Serbia" },
      { id: "ru", nombre: "Rusia" },
      { id: "rw", nombre: "Ruanda" },
      { id: "sa", nombre: "Arabia Saudita" },
      { id: "sb", nombre: "Islas Salomón" },
      { id: "sc", nombre: "Seychelles" },
      { id: "sd", nombre: "Sudán" },
      { id: "se", nombre: "Suecia" },
      { id: "sg", nombre: "Singapur" },
      { id: "sh", nombre: "Santa Elena, Ascensión y Tristán de Acuña" },
      { id: "si", nombre: "Eslovenia" },
      { id: "sj", nombre: "Svalbard y Jan Mayen" },
      { id: "sk", nombre: "Eslovaquia" },
      { id: "sl", nombre: "Sierra Leona" },
      { id: "sm", nombre: "San Marino" },
      { id: "sn", nombre: "Senegal" },
      { id: "so", nombre: "Somalia" },
      { id: "sr", nombre: "Surinam" },
      { id: "ss", nombre: "Sudán del Sur" },
      { id: "st", nombre: "Santo Tomé y Príncipe" },
      { id: "sv", nombre: "El Salvador" },
      { id: "sx", nombre: "San Martín (Países Bajos)" },
      { id: "sy", nombre: "Siria" },
      { id: "sz", nombre: "Suazilandia" },
      { id: "tc", nombre: "Islas Turcas y Caicos" },
      { id: "td", nombre: "Chad" },
      { id: "tf", nombre: "Tierras Australes y Antárticas Francesas" },
      { id: "tg", nombre: "Togo" },
      { id: "th", nombre: "Tailandia" },
      { id: "tj", nombre: "Tayikistán" },
      { id: "tk", nombre: "Tokelau" },
      { id: "tl", nombre: "Timor Oriental" },
      { id: "tm", nombre: "Turkmenistán" },
      { id: "tn", nombre: "Túnez" },
      { id: "to", nombre: "Tonga" },
      { id: "tr", nombre: "Turquía" },
      { id: "tt", nombre: "Trinidad y Tobago" },
      { id: "tv", nombre: "Tuvalu" },
      { id: "tw", nombre: "Taiwán" },
      { id: "tz", nombre: "Tanzania" },
      { id: "ua", nombre: "Ucrania" },
      { id: "ug", nombre: "Uganda" },
      { id: "um", nombre: "Islas Ultramarinas Menores de los Estados Unidos" },
      { id: "un", nombre: "Organización de las Naciones Unidas" },
      { id: "us", nombre: "Estados Unidos" },
      { id: "us-ak", nombre: "Alaska" },
      { id: "us-al", nombre: "Alabama" },
      { id: "us-ar", nombre: "Arkansas" },
      { id: "us-az", nombre: "Arizona" },
      { id: "us-ca", nombre: "California" },
      { id: "us-co", nombre: "Colorado" },
      { id: "us-ct", nombre: "Connecticut" },
      { id: "us-de", nombre: "Delaware" },
      { id: "us-fl", nombre: "Florida" },
      { id: "us-ga", nombre: "Georgia" },
      { id: "us-hi", nombre: "Hawái" },
      { id: "us-ia", nombre: "Iowa" },
      { id: "us-id", nombre: "Idaho" },
      { id: "us-il", nombre: "Illinois" },
      { id: "us-in", nombre: "Indiana" },
      { id: "us-ks", nombre: "Kansas" },
      { id: "us-ky", nombre: "Kentucky" },
      { id: "us-la", nombre: "Luisiana" },
      { id: "us-ma", nombre: "Massachusetts" },
      { id: "us-md", nombre: "Maryland" },
      { id: "us-me", nombre: "Maine" },
      { id: "us-mi", nombre: "Míchigan" },
      { id: "us-mn", nombre: "Minnesota" },
      { id: "us-mo", nombre: "Misuri" },
      { id: "us-ms", nombre: "Misisipi" },
      { id: "us-mt", nombre: "Montana" },
      { id: "us-nc", nombre: "Carolina del Norte" },
      { id: "us-nd", nombre: "Dakota del Norte" },
      { id: "us-ne", nombre: "Nebraska" },
      { id: "us-nh", nombre: "Nuevo Hampshire" },
      { id: "us-nj", nombre: "Nueva Jersey" },
      { id: "us-nm", nombre: "Nuevo México" },
      { id: "us-nv", nombre: "Nevada" },
      { id: "us-ny", nombre: "Nueva York" },
      { id: "us-oh", nombre: "Ohio" },
      { id: "us-ok", nombre: "Oklahoma" },
      { id: "us-or", nombre: "Oregón" },
      { id: "us-pa", nombre: "Pensilvania" },
      { id: "us-ri", nombre: "Rhode Island" },
      { id: "us-sc", nombre: "Carolina del Sur" },
      { id: "us-sd", nombre: "Dakota del Sur" },
      { id: "us-tn", nombre: "Tennessee" },
      { id: "us-tx", nombre: "Texas" },
      { id: "us-ut", nombre: "Utah" },
      { id: "us-va", nombre: "Virginia" },
      { id: "us-vt", nombre: "Vermont" },
      { id: "us-wa", nombre: "Washington" },
      { id: "us-wi", nombre: "Wisconsin" },
      { id: "us-wv", nombre: "Virginia Occidental" },
      { id: "us-wy", nombre: "Wyoming" },
      { id: "uy", nombre: "Uruguay" },
      { id: "uz", nombre: "Uzbekistán" },
      { id: "va", nombre: "Ciudad del Vaticano" },
      { id: "vc", nombre: "San Vicente y las Granadinas" },
      { id: "ve", nombre: "Venezuela" },
      { id: "vg", nombre: "Islas Vírgenes Británicas" },
      { id: "vi", nombre: "Islas Vírgenes de los Estados Unidos" },
      { id: "vn", nombre: "Vietnam" },
      { id: "vu", nombre: "Vanuatu" },
      { id: "wf", nombre: "Wallis y Futuna" },
      { id: "ws", nombre: "Samoa" },
      { id: "xk", nombre: "Kosovo" },
      { id: "ye", nombre: "Yemen" },
      { id: "yt", nombre: "Mayotte" },
      { id: "za", nombre: "Sudáfrica" },
      { id: "zm", nombre: "Zambia" },
      { id: "zw", nombre: "Zimbabue" },
    ]);

    fetch(process.env.REACT_APP_DEFAULT_URL + "/api/agenda/info", {
      cache: "no-cache",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setImgAgenda(data.imagen);
        setTitulo(data.titulo);
      });
    if (props.hash_asesor != null) {
      fetch(
        process.env.REACT_APP_DEFAULT_URL +
          "/api/read_hash/asesor/" +
          props.hash_asesor,
        { cache: "no-cache" }
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setAsesor(data.rows[0].UniqueID);
          if (props.cortesia) {
            setComentario(
              "CORTESÍA AUTORIZADA POR: " +
                data.rows[0].Nombres +
                " " +
                data.rows[0].Apellidos
            );
          }
        });
    }
    if (props.lead != null) {
      fetch(
        process.env.REACT_APP_DEFAULT_URL + "/api/read/lead/" + props.lead,
        { cache: "no-cache", credentials: "include" }
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setLead(data.rows[0]);
          let tmp = [];
          tmp.push({
            Nombres: data.rows[0].nombre,
            Apellidos: "",
            Puesto: data.rows[0].puesto,
            Correo: data.rows[0].correo,
            Tel: data.rows[0].telefono,
            Celular: "",
            Pais: "",
          });
          setParticipantes(tmp);
          setAsesor(data.rows[0].asesor_UniqueID);
          setInstitucion(data.rows[0].institucion);
        });
    }
  }, [contador]);
  useEffect(() => {
    let inversion_total = 0;
    if (!props.cortesia) {
      if (agremiados) {
        inversion_total = evento.costo_agremiados * participantes.length;
      } else {
        inversion_total = evento.costo_no_agremiados * participantes.length;
      }
      if (props.hash_descuento != null && descuento.descuento != null) {
        setInversion(inversion_total - descuento.descuento);
      } else {
        setInversion(inversion_total);
      }
    }
    if (inversion_total == 0 || props.cortesia) {
      setFormaPago(15);
    }
  }, [cambios]);

  const addParticipante = () => {
    let tmp = participantes;
    tmp.push({
      Nombres: "",
      Apellidos: "",
      Puesto: "",
      Correo: "",
      Tel: "",
      Celular: "",
      Pais: "",
    });
    setParticipantes(tmp);
    setCambios(cambios + 1);
  };
  const deleteParticipante = (indice) => {
    let tmp = participantes;
    tmp.splice(indice, 1);
    if (tmp.length == 0) {
      tmp.push({
        Nombres: "",
        Apellidos: "",
        Puesto: "",
        Correo: "",
        Tel: "",
        Celular: "",
        Pais: "",
      });
    }
    setParticipantes(tmp);
    setCambios(cambios + 1);
  };
  const onSubmit = (evt) => {
    const form = evt.currentTarget;
    if (form.checkValidity() === false) {
      evt.preventDefault();
      evt.stopPropagation();
    } else {
      evt.preventDefault();
      cargar("Validando información...");
      const body_validar_correos = { participantes: participantes };
      const requestOptionsValidarEmail = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body_validar_correos),
      };
      fetch(
        process.env.REACT_APP_DEFAULT_URL +
          "/api/read/detalle_inscripcion/search/email/" +
          evento.UniqueID,
        requestOptionsValidarEmail
      )
        .then((resValidarCorreos) => {
          return resValidarCorreos.json();
        })
        .then((validarCorreos) => {
          if (validarCorreos.salida.length > 0) {
            operacionError(
              "Hay uno o varios emails que ya se encuentran registrados"
            );
          } else {
            const infoInscripcion = {
              participantes: participantes,
              Agremiado: agremiados,
              Inversion: inversion,
              Fecha_Max_FACT: fechaMax,
              Nombre_FACT: nombreFactura,
              NIT: nit,
              Direccion: direccion,
              Correo_FACT: correoFactura,
              Responsable: "",
              Correo_RESP: "",
              Tel_RESP: "",
              Responsable_PAGO: "",
              Correo_RP: "",
              Tel_RP: "",
              Comentario: comentario,
              Evento_Unique_ID: evento.UniqueID,
              Forma_Pago_UniqueID: formaPago,
              Moneda_Unique_ID: 8,
              moneda: "QTZ",
              Asesor_UniqueID: asesor,
              empresa: institucion,
            };
            const requestOptionsInscripcion = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(infoInscripcion),
            };
            fetch(
              process.env.REACT_APP_DEFAULT_URL + "/api/inscribir",
              requestOptionsInscripcion
            )
              .then((res) => {
                return res.json();
              })
              .then((data) => {
                if (data.codigo == 200) {
                  if (props.hash_descuento != null && descuento != null) {
                    const info = {
                      hash: props.hash_descuento,
                    };
                    const requestOptions = {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify(info),
                    };
                    fetch(
                      process.env.REACT_APP_DEFAULT_URL +
                        "/api/descuentos/canjear",
                      requestOptions
                    )
                      .then((respuesta_canje) => {
                        return respuesta_canje.json();
                      })
                      .then((dataCanje) => {
                        confirmaciones(data);
                      });
                  } else {
                    confirmaciones(data);
                  }
                } else {
                  operacionError(
                    "Ha ocurrido un error al ingresar la inscripción."
                  );
                }
              });
          }
        });
    }
    setValidated(true);
    setCambios(cambios + 1);
  };
  const confirmaciones = (data) => {
    setIdInscripcion(data.id);
    setCambios(cambios + 1);
    const index_finder = (curr) => curr.UniqueID == asesor;
    let email_asesor = asesores[asesores.findIndex(index_finder)].email;
    if (inversion == 0) {
      const infoCorreoConfirmacion = {
        participantes: participantes,
        titulo: evento.Nombre,
        promocional: evento.flier,
        enlace:
          process.env.REACT_APP_URL_ADMIN +
          "/inscripcion/public/ver/" +
          data.id,
        dia: evento.dia,
        mes_anio: evento.mes_anio,
        email_asesor: email_asesor,
      };
      const requestOptionsCorreoConfirmacion = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(infoCorreoConfirmacion),
      };
      const infoConfirmacionPago = { UniqueID: data.id };
      const requestOptionsConfirmacionPago = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(infoConfirmacionPago),
      };
      fetch(
        process.env.REACT_APP_DEFAULT_URL +
          "/api/update/inscripcion/confirmar_pago",
        requestOptionsConfirmacionPago
      )
        .then((resConfirmacionPago) => {
          return resConfirmacionPago.json();
        })
        .then((dataConfirmacionPago) => {
          if (dataConfirmacionPago.codigo == 200) {
            fetch(
              process.env.REACT_APP_DEFAULT_URL +
                "/api/email/informar/inscripcion/cortesia",
              requestOptionsCorreoConfirmacion
            )
              .then((resEmailConfirmacion) => {
                return resEmailConfirmacion.json();
              })
              .then((dataEmailConfirmacion) => {
                operacionExito("Inscripción registrada exitosamente");
                if (sesiones.length > 0) {
                  if (sesiones[0].enlaces.length > 0) {
                    window.location.replace(sesiones[0].enlaces[0].url);
                  }
                }
              });
          } else {
            operacionError("Ha ocurrido un error al confirmar la inscripción.");
          }
        });
    } else {
      if (comprobante != null) {
        const formData = new FormData();
        formData.append("file", comprobante);
        axios
          .post(
            process.env.REACT_APP_DEFAULT_URL + "/api/files/upload/s3",
            formData,
            {}
          )
          .then((res) => {
            if (res.data.codigo == 200) {
              const info = {
                comprobante: res.data.resultado.Location,
                id: data.id,
              };
              const requestOptions = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(info),
              };
              fetch(
                process.env.REACT_APP_DEFAULT_URL +
                  "/api/inscripcion/set-comprobante",
                requestOptions
              )
                .then((responseSetComprobante) => {
                  return responseSetComprobante.json();
                })
                .then((dataSetComprobante) => {
                  if (dataSetComprobante.codigo == 200) {
                    const infoConfirmacionPago = { UniqueID: data.id };
                    const requestOptionsConfirmacionPago = {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify(infoConfirmacionPago),
                    };
                    fetch(
                      process.env.REACT_APP_DEFAULT_URL +
                        "/api/update/inscripcion/confirmar_pago",
                      requestOptionsConfirmacionPago
                    )
                      .then((resConfirmacionPago) => {
                        return resConfirmacionPago.json();
                      })
                      .then((dataConfirmacionPago) => {
                        const infoCorreoConfirmacion = {
                          participantes: participantes,
                          titulo: evento.Nombre,
                          promocional: evento.flier,
                          enlace:
                            process.env.REACT_APP_URL_ADMIN +
                            "/inscripcion/public/ver/" +
                            data.id,
                          enlace_seguimiento:
                            process.env.REACT_APP_URL_ADMIN +
                            "/inscripcion/seguimiento/" +
                            data.id,
                          dia: evento.dia,
                          mes_anio: evento.mes_anio,
                          email_asesor: email_asesor,
                        };
                        const requestOptionsCorreoConfirmacion = {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify(infoCorreoConfirmacion),
                        };
                        fetch(
                          process.env.REACT_APP_DEFAULT_URL +
                            "/api/email/informar/inscripcion/pagada",
                          requestOptionsCorreoConfirmacion
                        )
                          .then((resEmailConfirmacion) => {
                            return resEmailConfirmacion.json();
                          })
                          .then((dataEmailConfirmacion) => {
                            operacionExito(
                              "Inscripción registrada exitosamente"
                            );
                            if (formaPago == 14) {
                              setPasarela("block");
                              /*let header = document.getElementById('header');
                                                        let outlet = document.getElementById('outlet');
                                                        let footer = document.getElementById('footer');*/
                              let pasarela =
                                document.getElementById("pasarela");
                              //window.scrollTo(0, header.scrollHeight + outlet.scrollHeight + pasarela.scrollHeight);
                              pasarela.scrollIntoView(true);
                              setCambios(cambios + 1);
                            }
                          });
                      });
                  } else {
                    const infoCorreoConfirmacion = {
                      participantes: participantes,
                      titulo: evento.Nombre,
                      promocional: evento.flier,
                      enlace:
                        process.env.REACT_APP_URL_ADMIN +
                        "/inscripcion/public/ver/" +
                        data.id,
                      enlace_seguimiento:
                        process.env.REACT_APP_URL_ADMIN +
                        "/inscripcion/seguimiento/" +
                        data.id,
                      dia: evento.dia,
                      mes_anio: evento.mes_anio,
                      email_asesor: email_asesor,
                    };
                    const requestOptionsCorreoConfirmacion = {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify(infoCorreoConfirmacion),
                    };
                    fetch(
                      process.env.REACT_APP_DEFAULT_URL +
                        "/api/email/informar/inscripcion/pagada",
                      requestOptionsCorreoConfirmacion
                    )
                      .then((resEmailConfirmacion) => {
                        return resEmailConfirmacion.json();
                      })
                      .then((dataEmailConfirmacion) => {
                        operacionExito("Inscripción registrada exitosamente");
                        if (formaPago == 14) {
                          setPasarela("block");
                          /*let header = document.getElementById('header');
                                                let outlet = document.getElementById('outlet');
                                                let footer = document.getElementById('footer');*/
                          let pasarela = document.getElementById("pasarela");
                          //window.scrollTo(0, header.scrollHeight + outlet.scrollHeight + pasarela.scrollHeight);
                          pasarela.scrollIntoView(true);
                          setCambios(cambios + 1);
                        }
                      });
                  }
                });
            } else {
              const infoCorreoConfirmacion = {
                participantes: participantes,
                titulo: evento.Nombre,
                promocional: evento.flier,
                enlace:
                  process.env.REACT_APP_URL_ADMIN +
                  "/inscripcion/public/ver/" +
                  data.id,
                enlace_seguimiento:
                  process.env.REACT_APP_URL_ADMIN +
                  "/inscripcion/seguimiento/" +
                  data.id,
                dia: evento.dia,
                mes_anio: evento.mes_anio,
                email_asesor: email_asesor,
              };
              const requestOptionsCorreoConfirmacion = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(infoCorreoConfirmacion),
              };
              fetch(
                process.env.REACT_APP_DEFAULT_URL +
                  "/api/email/informar/inscripcion/pagada",
                requestOptionsCorreoConfirmacion
              )
                .then((resEmailConfirmacion) => {
                  return resEmailConfirmacion.json();
                })
                .then((dataEmailConfirmacion) => {
                  operacionExito("Inscripción registrada exitosamente");
                  if (formaPago == 14) {
                    setPasarela("block");
                    /*let header = document.getElementById('header');
                                    let outlet = document.getElementById('outlet');
                                    let footer = document.getElementById('footer');*/
                    let pasarela = document.getElementById("pasarela");
                    //window.scrollTo(0, header.scrollHeight + outlet.scrollHeight + pasarela.scrollHeight);
                    pasarela.scrollIntoView(true);
                    setCambios(cambios + 1);
                  }
                });
            }
          });
      } else {
        const infoCorreoConfirmacion = {
          participantes: participantes,
          titulo: evento.Nombre,
          promocional: evento.flier,
          enlace:
            process.env.REACT_APP_URL_ADMIN +
            "/inscripcion/public/ver/" +
            data.id,
          enlace_seguimiento:
            process.env.REACT_APP_URL_ADMIN +
            "/inscripcion/seguimiento/" +
            data.id,
          dia: evento.dia,
          mes_anio: evento.mes_anio,
          email_asesor: email_asesor,
        };
        const requestOptionsCorreoConfirmacion = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(infoCorreoConfirmacion),
        };
        fetch(
          process.env.REACT_APP_DEFAULT_URL +
            "/api/email/informar/inscripcion/pagada",
          requestOptionsCorreoConfirmacion
        )
          .then((resEmailConfirmacion) => {
            return resEmailConfirmacion.json();
          })
          .then((dataEmailConfirmacion) => {
            operacionExito("Inscripción registrada exitosamente");
            if (formaPago == 14) {
              setPasarela("block");
              /*let header = document.getElementById('header');
                            let outlet = document.getElementById('outlet');
                            let footer = document.getElementById('footer');*/
              let pasarela = document.getElementById("pasarela");
              //window.scrollTo(0, header.scrollHeight + outlet.scrollHeight + pasarela.scrollHeight);
              pasarela.scrollIntoView(true);
            }
          });
      }
    }
  };
  const cargarParticipantes = (array) => {
    if (cargando != "block" && error != "block" && exito != "block") {
      let tmp = [];
      array.map((curr) => {
        tmp.push({
          No: tmp.length,
          identificacion: "",
          Nombres: curr.NOMBRES,
          Apellidos: curr.APELLIDOS,
          Puesto: curr.PUESTO,
          Correo: curr["CORREO ELECTRONICO"],
          Tel: curr["TELEFONO Y EXTENSION"],
          Celular: curr.CELULAR,
          Pais: curr.PAIS,
        });
      });
      setParticipantes(tmp);
      setCambios(cambios + 1);
    }
  };
  const onChangeParticipante = (evt) => {
    const arr_name = evt.target.name.split("-");
    var new_array = participantes;
    switch (arr_name[0]) {
      case "nombres":
        new_array[arr_name[1]].Nombres = evt.target.value;
        setParticipantes(new_array);
        break;
      case "apellidos":
        new_array[arr_name[1]].Apellidos = evt.target.value;
        setParticipantes(new_array);
        break;
      case "puesto":
        new_array[arr_name[1]].Puesto = evt.target.value;
        setParticipantes(new_array);
        break;
      case "correo":
        new_array[arr_name[1]].Correo = evt.target.value;
        setParticipantes(new_array);
        break;
      case "telefono":
        new_array[arr_name[1]].Tel = evt.target.value;
        setParticipantes(new_array);
        break;
      case "celular":
        new_array[arr_name[1]].Celular = evt.target.value;
        setParticipantes(new_array);
        break;
      case "identificacion":
        new_array[arr_name[1]].identificacion = evt.target.value;
        setParticipantes(new_array);
        break;
      case "pais":
        new_array[arr_name[1]].Pais = evt.target.value;
        setParticipantes(new_array);
        break;
      default:
        break;
    }
    setCambios(cambios + 1);
  };
  const onChange = (evt) => {
    if (evt.target.name == "agremiados") {
      setAgremiados(evt.target.checked);
    } else if (evt.target.name == "institucion-agremiada") {
      const index_finder = (institucion_agremiada) =>
        institucion_agremiada.INSTITUCION == evt.target.value;
      if (institucionesAgremiadas.findIndex(index_finder) >= 0) {
        let institucion_agremiada =
          institucionesAgremiadas[
            institucionesAgremiadas.findIndex(index_finder)
          ];
        setNit(institucion_agremiada.NIT);
        setNombreFactura(institucion_agremiada.INSTITUCION);
        setDireccion(institucion_agremiada.DIRECCION);
        setFormaPago(institucion_agremiada.FORMA_PAGO);
      }
      setInstitucion(evt.target.value);
    } else if (evt.target.name == "institucion") {
      setInstitucion(evt.target.value);
    } else if (evt.target.name == "fecha-maxima") {
      setFechaMax(evt.target.value);
    } else if (evt.target.name == "nit") {
      setNit(evt.target.value);
    } else if (evt.target.name == "nombre-factura") {
      setNombreFactura(evt.target.value);
    } else if (evt.target.name == "direccion") {
      setDireccion(evt.target.value);
    } else if (evt.target.name == "correo-factura") {
      setCorreoFactura(evt.target.value);
    } else if (evt.target.name == "forma-pago") {
      setFormaPago(evt.target.value);
    } else if (evt.target.name == "comentario") {
      setComentario(evt.target.value);
    } else if (evt.target.name == "asesor") {
      setAsesor(evt.target.value);
    } else if (evt.target.name == "file") {
      setComprobante(evt.target.files[0]);
    }
    setCambios(cambios + 1);
  };
  const cargar = () => {
    setCargando("block");
    setExito("none");
    setError("none");
    setMensaje("Procesando información...");
    setCambios(cambios + 1);
  };
  const operacionExito = (log) => {
    setCargando("none");
    setExito("block");
    setError("none");
    setMensaje(log);
    setCambios(cambios + 1);
  };
  const operacionError = (log) => {
    setCargando("none");
    setExito("none");
    setError("block");
    setMensaje(log);
    setCambios(cambios + 1);
  };
  const retry = () => {
    setCargando("none");
    setExito("none");
    setError("none");
    setMensaje("");
    setCambios(cambios + 1);
  };
  const print = () => {
    if (idInscripcion != "" && idInscripcion != null) {
      cargar("Generando ficha en PDF..");
      fetch(
        process.env.REACT_APP_DEFAULT_URL + "/api/print/sheet/" + idInscripcion,
        { cache: "no-cache" }
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          // tmp[indice].enlacePDF = data.enlace_S3;
          operacionExito("PDF generado exitosamente");
          window.open(data.enlace_S3, "_blank");
        });
    } else {
      operacionError("Ocurrió un error al generar PDF");
    }
  };
  const goBottom = () => {
    let footer = document.getElementById("carga-masiva");
    footer.scrollIntoView();
  };
  return (
    <Container fluid>
      <div id="div-boton-inscribirse">
        <Container fluid>
          <Row>
            <Button variant="dark" onClick={goBottom}>
              Inscribirme ya
            </Button>
          </Row>
        </Container>
      </div>
      <Row
        style={{
          backgroundColor: "rgb(230, 230, 230)",
          padding: "2%",
          textAlign: "left",
          borderTop: "5px solid #003188",
          borderBottom: "5px solid #003188",
        }}
        id="header-evt"
      >
        <Col xs={12} md={12} lg={2} style={{ padding: "1%" }}>
          <img src={imgAgenda} style={{ width: "100%", borderRadius: "2em" }} />
        </Col>
        <Col xs={12} md={12} lg={10} style={{ padding: "1%" }}>
          <h1>
            <strong>{evento.tipo}</strong>
          </h1>
          <h3>
            <strong>{titulo}</strong>
          </h3>
        </Col>
      </Row>
      <Row style={{ padding: "2%" }} id="info-evt">
        <Container>
          <Row>
            <Col xs={12} md={12} lg={6}>
              <img src={evento.flier} alt="img-evt" style={{ width: "75%" }} />
            </Col>
            <Col
              xs={12}
              md={12}
              lg={6}
              style={{
                textAlign: "right",
                display: "grid",
                placeItems: "center",
              }}
            >
              <Container fluid>
                <Row>
                  <h2>
                    <strong>{evento.Nombre}</strong>
                  </h2>
                  <p>
                    <strong>Inicia: </strong>
                    {evento.Fecha_Inicio}
                  </p>
                  <p>
                    <strong>Finaliza: </strong>
                    {evento.Fecha_Fin}
                  </p>
                  <p>
                    <strong>Días a impartir: </strong>
                    {evento.dias}
                  </p>
                  <p>
                    <strong>Hora: </strong>
                    {evento.Hora}
                  </p>
                  <p>
                    <strong>Duración: </strong>
                    {evento.duracion}
                  </p>
                  <p>
                    <strong>No. de sesiones: </strong>
                    {evento.sesiones}
                  </p>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </Row>
      <Row
        style={{
          textAlign: "center",
          padding: "2%",
          borderTop: "5px solid #003188",
          borderBottom: "5px solid #003188",
          backgroundColor: "rgb(230, 230, 230)",
        }}
        id="registro"
      >
        <h1>
          <strong>REGISTRO</strong>
        </h1>
      </Row>
      <Row style={{ padding: "2%" }}>
        <h3 style={{ textAlign: "left" }}>
          <strong>INFORMACIÓN DE PARTICIPANTES</strong>
        </h3>
      </Row>
      <Row id="carga-masiva">
        <CargaMasiva
          cargarParticipantes={cargarParticipantes}
          block={cargando == "block" || error == "block" || exito == "block"}
        />
      </Row>
      <Row style={{ paddingTop: "2%", paddingBottom: "2%" }}>
        <Form noValidate validated={validated} onSubmit={onSubmit}>
          <Container fluid>
            <Row>
              {participantes.map((actual, index) => (
                <Col
                  xs={12}
                  md={6}
                  lg={4}
                  key={index}
                  style={{ padding: "2%", marginTop: "50px" }}
                >
                  <Container
                    style={{
                      backgroundColor: "#E6E6E6",
                      padding: "2%",
                      borderRadius: "2em",
                    }}
                  >
                    <Row style={{ padding: "2%" }}>
                      <Col xs={3} md={3} lg={3}>
                        <Button
                          variant="outline-danger"
                          disabled={
                            cargando == "block" ||
                            exito == "block" ||
                            error == "block"
                          }
                          onClick={() => {
                            deleteParticipante(index);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-trash3-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                          </svg>
                        </Button>
                      </Col>
                      <Col xs={9} md={9} lg={9}>
                        <h3 style={{ textAlign: "left" }}>
                          <strong>Participante #{index + 1}</strong>
                        </h3>
                      </Col>
                    </Row>
                    <Row style={{ padding: "2%" }}>
                      <Form.Group controlId="nombre">
                        <Form.Label>Nombres</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Por favor, ingrese sus nombres"
                          name={"nombres-" + index}
                          onChange={onChangeParticipante}
                          value={actual.Nombres}
                          disabled={
                            cargando == "block" ||
                            exito == "block" ||
                            error == "block"
                          }
                        />
                        <Form.Control.Feedback>Ok</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Por favor, ingrese sus nombres
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row style={{ padding: "2%" }}>
                      <Form.Group controlId="apellidos">
                        <Form.Label>Apellidos</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Por favor, ingrese sus apellidos"
                          name={"apellidos-" + index}
                          onChange={onChangeParticipante}
                          value={actual.Apellidos}
                          disabled={
                            cargando == "block" ||
                            exito == "block" ||
                            error == "block"
                          }
                        />
                        <Form.Control.Feedback>Ok</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Por favor, ingrese sus apellidos
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row style={{ padding: "2%" }}>
                      <Form.Group controlId="puesto">
                        <Form.Label>Puesto</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Por favor, ingrese su puesto"
                          name={"puesto-" + index}
                          onChange={onChangeParticipante}
                          value={actual.Puesto}
                          disabled={
                            cargando == "block" ||
                            exito == "block" ||
                            error == "block"
                          }
                        />
                        <Form.Control.Feedback>Ok</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Por favor, ingrese su puesto
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row style={{ padding: "2%" }}>
                      <Form.Group controlId="correo">
                        <Form.Label>Correo</Form.Label>
                        <Form.Control
                          required
                          type="email"
                          placeholder="Por favor, ingrese su correo electrónico"
                          name={"correo-" + index}
                          onChange={onChangeParticipante}
                          value={actual.Correo}
                          disabled={
                            cargando == "block" ||
                            exito == "block" ||
                            error == "block"
                          }
                        />
                        <Form.Control.Feedback>Ok</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Por favor, ingrese su correo electrónico
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row style={{ padding: "2%" }}>
                      <Form.Group controlId="telefono">
                        <Form.Label>Teléfono</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Por favor, ingrese su No. teléfono"
                          name={"telefono-" + index}
                          onChange={onChangeParticipante}
                          value={actual.Tel}
                          disabled={
                            cargando == "block" ||
                            exito == "block" ||
                            error == "block"
                          }
                        />
                        <Form.Control.Feedback>Ok</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Por favor, ingrese su No. teléfono
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row style={{ padding: "2%" }}>
                      <Form.Group controlId="celular">
                        <Form.Label>Celular</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Por favor, ingrese su No. celular"
                          name={"celular-" + index}
                          onChange={onChangeParticipante}
                          value={actual.Celular}
                          disabled={
                            cargando == "block" ||
                            exito == "block" ||
                            error == "block"
                          }
                        />
                        <Form.Control.Feedback>Ok</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Por favor, ingrese su No. celular
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>

                    <Row style={{ padding: "2%" }}>
                      <Form.Group controlId="pais">
                        <Form.Label>Pais</Form.Label>
                        <Form.Select
                          required
                          onChange={onChangeParticipante}
                          name={"pais-" + index}
                          value={actual.Pais}
                          disabled={
                            cargando == "block" ||
                            exito == "block" ||
                            error == "block"
                          }
                        >
                          <option value="">Seleccione el país</option>
                          {paises.map((curr, indice) => (
                            <option
                              key={curr.id + "-" + indice}
                              value={curr.nombre}
                            >
                              {curr.nombre}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Control.Feedback>Ok</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Por favor, seleccione el país
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                  </Container>
                </Col>
              ))}
            </Row>
            <Row
              style={{
                paddingTop: "2%",
                paddingBottom: "2%",
                paddingLeft: "25%",
                paddingRight: "25%",
              }}
            >
              <Button
                variant="outline-dark"
                onClick={addParticipante}
                disabled={
                  cargando == "block" || exito == "block" || error == "block"
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-person-plus-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                  <path
                    fillRule="evenodd"
                    d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
                  />
                </svg>
                &nbsp;&nbsp;&nbsp;&nbsp;Agregar participante
              </Button>
            </Row>
            <Row style={{ padding: "1%" }}>
              <h3 style={{ textAlign: "left" }}>
                <strong>INFORMACIÓN INSTITUCIONAL</strong>
              </h3>
            </Row>
            <Row style={{ padding: "1%" }}>
              <Form.Check
                value={agremiados}
                onChange={onChange}
                style={{ textAlign: "left" }}
                type="checkbox"
                name="agremiados"
                label="La institución es una entidad agremiada a la ABG"
                disabled={
                  cargando == "block" || exito == "block" || error == "block"
                }
              />
            </Row>
            <Row style={{ padding: "1%" }}>
              <Form.Group controlId="institucion">
                <Form.Label>Institución</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Por favor, ingrese la institución"
                  name="institucion"
                  onChange={onChange}
                  value={institucion}
                  disabled={
                    agremiados ||
                    cargando == "block" ||
                    exito == "block" ||
                    error == "block"
                  }
                />
                <Form.Control.Feedback>Ok</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Por favor, ingrese la institución
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row style={{ padding: "1%" }}>
              <Form.Group controlId="institucion-agremiada">
                <Form.Label>Institución agremiada a la ABG</Form.Label>
                <Form.Select
                  required={agremiados}
                  onChange={onChange}
                  name="institucion-agremiada"
                  disabled={
                    !agremiados ||
                    cargando == "block" ||
                    exito == "block" ||
                    error == "block"
                  }
                  value={institucion}
                >
                  <option value="">Seleccione la institucion</option>
                  {institucionesAgremiadas.map((actual) => (
                    <option key={actual.NO} value={actual.INSTITUCION}>
                      {actual.INSTITUCION}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback>Ok</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Por favor, seleccione la institución agremiada
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row style={{ padding: "1%" }}>
              <Form.Group controlId="asesor">
                <Form.Label>Seleccione el asesor que le atendió</Form.Label>
                <Form.Select
                  required
                  name="asesor"
                  onChange={onChange}
                  value={asesor}
                  disabled={
                    cargando == "block" ||
                    exito == "block" ||
                    error == "block" ||
                    props.hash_asesor != null
                  }
                >
                  <option value="">Seleccione el asesor que le atendió</option>
                  {asesores.map((asesor) => (
                    <option key={asesor.UniqueID} value={asesor.UniqueID}>
                      {asesor.Nombres + " " + asesor.Apellidos}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback>Ok</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Por favor, seleccione el asesor que le atendió
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            {evento.costo_agremiados > 0 &&
              evento.costo_no_agremiados > 0 &&
              !props.cortesia && (
                <Row
                  style={{
                    textAlign: "left",
                    padding: "2%",
                    borderTop: "5px solid #003188",
                    borderBottom: "5px solid #003188",
                    backgroundColor: "rgb(230, 230, 230)",
                  }}
                >
                  <h3 style={{ textAlign: "center" }}>
                    <strong>INFORMACIÓN DE FACTURACIÓN</strong>
                  </h3>
                </Row>
              )}
            {evento.costo_agremiados > 0 &&
              evento.costo_no_agremiados > 0 &&
              !props.cortesia && (
                <Row style={{ textAlign: "center", padding: "1%" }}>
                  <h4>
                    <strong>
                      TOTAL A PAGAR:{" "}
                      {"Q " +
                        inversion
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </strong>
                  </h4>
                </Row>
              )}
            {evento.costo_agremiados > 0 &&
              evento.costo_no_agremiados > 0 &&
              descuento.descuento != null &&
              !props.cortesia && (
                <Row style={{ textAlign: "center", padding: "1%" }}>
                  <h4>
                    <strong>
                      DESCUENTO AUTORIZADO:{" "}
                      {"Q " +
                        descuento.descuento
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </strong>
                  </h4>
                </Row>
              )}
            {evento.costo_agremiados > 0 &&
              evento.costo_no_agremiados > 0 &&
              !props.cortesia && (
                <Row style={{ padding: "1%" }}>
                  <Col xs={12} md={6} lg={4} style={{ marginTop: "25px" }}>
                    <Form.Group controlId="fecha-maxima">
                      <Form.Label>Fecha máxima que reciben facturas</Form.Label>
                      <Form.Control
                        required
                        type="date"
                        placeholder="Por favor, ingrese la fecha máxima que reciben facturas"
                        name="fecha-maxima"
                        onChange={onChange}
                        value={fechaMax}
                        disabled={
                          cargando == "block" ||
                          exito == "block" ||
                          error == "block" ||
                          evento.costo_agremiados == 0 ||
                          evento.costo_no_agremiados == 0
                        }
                      />
                      <Form.Control.Feedback>Ok</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Por favor, ingrese la fecha máxima que reciben facturas
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6} lg={4} style={{ marginTop: "25px" }}>
                    <Form.Group controlId="nit">
                      <Form.Label>Nit para factura</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Por favor, ingrese el nit para su factura"
                        name="nit"
                        pattern="([0-9]+K*)|CF"
                        onChange={onChange}
                        disabled={
                          agremiados ||
                          cargando == "block" ||
                          exito == "block" ||
                          error == "block" ||
                          evento.costo_agremiados == 0 ||
                          evento.costo_no_agremiados == 0
                        }
                        value={nit}
                      />
                      <Form.Control.Feedback>Ok</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Por favor, ingrese el nit para su factura
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6} lg={4} style={{ marginTop: "25px" }}>
                    <Form.Group controlId="nombre-factura">
                      <Form.Label>Nombre para factura</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Por favor, ingrese el nombre para su factura"
                        name="nombre-factura"
                        onChange={onChange}
                        disabled={
                          agremiados ||
                          cargando == "block" ||
                          exito == "block" ||
                          error == "block" ||
                          evento.costo_agremiados == 0 ||
                          evento.costo_no_agremiados == 0
                        }
                        value={nombreFactura}
                      />
                      <Form.Control.Feedback>Ok</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Por favor, ingrese el nombre para su factura
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6} lg={4} style={{ marginTop: "25px" }}>
                    <Form.Group controlId="direccion-factura">
                      <Form.Label>Dirección para factura</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Por favor, ingrese dirección para factura"
                        name="direccion"
                        onChange={onChange}
                        value={direccion}
                        disabled={
                          agremiados ||
                          cargando == "block" ||
                          exito == "block" ||
                          error == "block" ||
                          evento.costo_agremiados == 0 ||
                          evento.costo_no_agremiados == 0
                        }
                      />
                      <Form.Control.Feedback>Ok</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Por favor, ingrese dirección para factura
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6} lg={4} style={{ marginTop: "25px" }}>
                    <Form.Group controlId="correo-factura">
                      <Form.Label>Correo para factura electrónica</Form.Label>
                      <Form.Control
                        required
                        type="email"
                        placeholder="Por favor, correo para enviar factura electrónica"
                        name="correo-factura"
                        onChange={onChange}
                        disabled={
                          cargando == "block" ||
                          exito == "block" ||
                          error == "block" ||
                          evento.costo_agremiados == 0 ||
                          evento.costo_no_agremiados == 0
                        }
                        value={correoFactura}
                      />
                      <Form.Control.Feedback>Ok</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Por favor, correo para enviar factura electrónica
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6} lg={4} style={{ marginTop: "25px" }}>
                    <Form.Group controlId="forma-pago">
                      <Form.Label>Forma de pago</Form.Label>
                      <Form.Select
                        required
                        name="forma-pago"
                        onChange={onChange}
                        value={formaPago}
                        disabled={
                          agremiados ||
                          cargando == "block" ||
                          exito == "block" ||
                          error == "block" ||
                          evento.costo_agremiados == 0 ||
                          evento.costo_no_agremiados == 0
                        }
                      >
                        <option value="">Seleccione su forma de pago</option>
                        {formasPago.map((forma_pago) => (
                          <option
                            key={forma_pago.UniqueID}
                            value={forma_pago.UniqueID}
                          >
                            {forma_pago.Nombre}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback>Ok</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Por favor, seleccione su forma de pago
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              )}
            {evento.costo_agremiados > 0 && evento.costo_no_agremiados > 0 && (
              <Row style={{ marginTop: "25px", marginBottom: "25px" }}>
                <Form.Group controlId="comentario">
                  <Form.Label>Comentario para el pago</Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Por favor, ingresa un comentario para su pago"
                    name="comentario"
                    onChange={onChange}
                    rows={3}
                    style={{ resize: "none" }}
                    value={comentario}
                    disabled={
                      cargando == "block" ||
                      exito == "block" ||
                      error == "block" ||
                      evento.costo_agremiados == 0 ||
                      evento.costo_no_agremiados == 0 ||
                      descuento.descuento != null ||
                      props.cortesia
                    }
                  />
                  <Form.Control.Feedback>Ok</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Por favor, ingresa un comentario para su pago
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
            )}
            {(formaPago == 16 || formaPago == 18 || formaPago == 19) && (
              <Row>
                <Container fluid>
                  <Row
                    style={{
                      padding: "2%",
                      borderTop: "5px solid #003188",
                      borderBottom: "5px solid #003188",
                      backgroundColor: "rgb(230, 230, 230)",
                    }}
                  >
                    <h3 style={{ textAlign: "center" }}>
                      <strong>
                        SCAN DE CHEQUE O COMPROBANTE DE DEPÓSITO/TRANSFERENCIA
                      </strong>
                    </h3>
                  </Row>
                  <Row style={{ padding: "2%", textAlign: "left" }}>
                    <h1>Tomar en cuenta:</h1>
                    <ul style={{ fontWeight: "bolder" }}>
                      <li>Unico formato permitido: PDF</li>
                      <li>
                        Solo seleccionar archivo, este se subirá junto al resto
                        del formulario
                      </li>
                      <li>
                        Cheques a nombre de Asociación Bancaria De Guatemala
                        “AUTORIZADO PAGO DIRECTO ISR RESOLUCIÓN
                        61021410202112542921”
                      </li>
                      <li>
                        Cuenta Monetaria Quetzales: No.053-449319-3 A nombre de
                        Asociación Bancaria de Guatemala
                      </li>
                      <li>
                        Cuenta Monetaria Dólares: No.053-061969-2 A nombre de
                        Asociación Bancaria de Guatemala
                      </li>
                      <li>
                        Cualquier duda comunicarse al 2382-7200 o a
                        ebg@ebg.edu.gt
                      </li>
                    </ul>
                    <Form.Group controlId="comentario">
                      <Form.Control
                        type="file"
                        onChange={onChange}
                        name="file"
                        disabled={
                          cargando == "block" ||
                          exito == "block" ||
                          error == "block" ||
                          evento.costo_agremiados == 0 ||
                          evento.costo_no_agremiados == 0 ||
                          descuento.descuento != null
                        }
                        accept="application/pdf"
                      />
                      <Form.Control.Feedback>Ok</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Por favor, ingresa tu scan de cheque o
                        depósito/transferencia
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                </Container>
              </Row>
            )}
            {evento.costo_agremiados > 0 && evento.costo_no_agremiados > 0 && (
              <Row
                style={{
                  textAlign: "left",
                  padding: "2%",
                  borderTop: "5px solid #003188",
                  borderBottom: "5px solid #003188",
                  backgroundColor: "rgb(230, 230, 230)",
                }}
              >
                <h3 style={{ textAlign: "center" }}>
                  <strong>POLÍTICAS Y CONDICIONES DE INSCRIPCIÓN</strong>
                </h3>
              </Row>
            )}
            {evento.costo_agremiados > 0 && evento.costo_no_agremiados > 0 && (
              <Row
                style={{
                  textAlign: "left",
                  padding: "2%",
                  backgroundColor: "rgb(230, 230, 230)",
                  marginTop: "25px",
                  marginBottom: "25px",
                }}
              >
                <p>
                  <strong>
                    Cheques a nombre de Asociación Bancaria De Guatemala
                    <br />
                    “AUTORIZADO PAGO DIRECTO ISR RESOLUCIÓN
                    61021410202112542921”
                  </strong>
                </p>
                <ul style={{ fontStyle: "italic" }}>
                  <li>
                    Para inscribirse en el evento, deberá completar el
                    formulario de inscripción correspondiente.
                  </li>
                  <li>
                    Toda inscripción al evento se formaliza con la recepción por
                    parte de la EBG del formulario de inscripción. La EBG EBC
                    enviará por correo una confirmación de inscripción.
                  </li>
                  <li>
                    La información recopilada por los formularios de inscripción
                    de la EBG EBC, será distribuida a las diferentes
                    organizaciones que hacen posible los eventos de la EBG EBC.
                  </li>
                  <li>
                    Al aceptar todos los términos y condiciones de inscripción
                    gratuita a los eventos de la EBG EBC, está autorizando a la
                    EBG EBC la distribución de la información que usted llenó en
                    los formularios a las organizaciones patrocinadoras.
                  </li>
                  <li>
                    Al aceptar todos los términos y condiciones de inscripción
                    gratuita a los eventos de la EBG EBC, está autorizando a las
                    diferentes organizaciones patrocinadoras a contactarse con
                    su persona.
                  </li>
                  <li>
                    Usted al llenar el formulario acepta todos los términos y
                    condiciones de inscripción gratuita a los eventos de la EBG
                    EBC.
                  </li>
                  <br />
                  <br />
                  <br />
                  <p style={{ fontStyle: "oblique", textAlign: "center" }}>
                    La educación es el arma más poderosa que puedes usar para
                    cambiar el mundo
                    <br />
                    <strong style={{ textAlign: "center" }}>
                      Nelson Mandela
                    </strong>
                  </p>
                </ul>
              </Row>
            )}
            <Row>
              <Col
                xs={12}
                md={12}
                lg={6}
                style={{ marginTop: "25px", marginBottom: "25px" }}
              >
                <Container fluid>
                  <Row>
                    <Col
                      xs={6}
                      md={6}
                      lg={6}
                      style={{ display: "grid", placeItems: "center" }}
                    >
                      <Spinner
                        animation="border"
                        role="status"
                        style={{ display: cargando }}
                      />
                      <svg
                        style={{ display: exito }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        fill="currentColor"
                        className="bi bi-check-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                      </svg>
                      <svg
                        style={{ display: error }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        fill="currentColor"
                        className="bi bi-exclamation-octagon-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                      </svg>
                    </Col>
                    <Col xs={6} md={6} lg={6}>
                      <p>{mensaje}</p>
                    </Col>
                  </Row>
                </Container>
              </Col>
              <Col
                xs={12}
                md={12}
                lg={6}
                style={{
                  marginTop: "25px",
                  marginBottom: "25px",
                  display: "grid",
                  alignItems: "center",
                }}
              >
                <Button
                  type="submit"
                  variant="outline-dark"
                  disabled={
                    cargando == "block" || exito == "block" || error == "block"
                  }
                >
                  Registrar inscripción
                </Button>
                <Button
                  variant="outline-dark"
                  onClick={retry}
                  disabled={
                    cargando == "block" || exito == "block" || error != "block"
                  }
                  style={{ marginTop: "15px" }}
                >
                  Reintentar
                </Button>
                <Button
                  variant="outline-success"
                  onClick={print}
                  style={{ marginTop: "15px" }}
                  disabled={
                    cargando == "block" || exito == "none" || error == "block"
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-filetype-pdf"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"
                    />
                  </svg>
                  &nbsp;&nbsp;&nbsp;&nbsp;Descargar ficha de inscripción en PDF
                </Button>
              </Col>
            </Row>
          </Container>
        </Form>
      </Row>
      <Row style={{ display: pasarela }} id="pasarela">
        <Container fluid>
          <Row
            style={{
              textAlign: "left",
              padding: "2%",
              borderTop: "5px solid #003188",
              borderBottom: "5px solid #003188",
              backgroundColor: "rgb(230, 230, 230)",
            }}
          >
            <h3 style={{ textAlign: "left" }}>
              <strong>PASARELA DE COBROS POR TARJETA DE CRÉDITO/DÉBITO</strong>
            </h3>
          </Row>
          <Row>
            {inversion != NaN && (
              <PasarelaForm
                total={inversion}
                concepto={
                  "Inscripción de " +
                  participantes.length +
                  ' participante(s) a evento "' +
                  evento.Nombre +
                  '", entidad: ' +
                  institucion +
                  "\nTOTAL: GTQ " +
                  inversion
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                inscripcion={idInscripcion}
                evento={evento.UniqueID}
                agremiados={agremiados}
                precio_agremiados={evento.costo_agremiados}
                precio_no_agremiados={evento.costo_no_agremiados}
              />
            )}
          </Row>
        </Container>
      </Row>
      <Row style={{ padding: "5%", display: exito }}>
        <Button
          variant="outline-dark"
          onClick={() => {
            window.location.reload();
          }}
          disabled={exito != "block"}
        >
          Registrar otra inscripción
        </Button>
      </Row>
      {evento.video_promo != null && (
        <Row
          style={{
            padding: "2%",
            textAlign: "left",
            borderTop: "5px solid #003188",
          }}
          id="video"
        >
          <section>
            <video autoPlay muted loop style={{ width: "100%" }}>
              <source src={evento.video_promo} type="video/mp4" />
            </video>
          </section>
        </Row>
      )}
      <Row id="jornadas">
        <Jornadas jornadas={jornadas} />
      </Row>
      <Row
        style={{
          textAlign: "center",
          padding: "2%",
          borderTop: "5px solid #003188",
          borderBottom: "5px solid #003188",
          backgroundColor: "rgb(230, 230, 230)",
        }}
        id="tarifas-titulo"
      >
        <h1>
          <strong>TARIFAS DE PARTICIPACIÓN</strong>
        </h1>
      </Row>
      <Row
        style={{
          paddingTop: "2%",
          paddingBottom: "2%",
          paddingLeft: "15%",
          paddingRight: "15%",
        }}
        id="tarifario"
      >
        {tarifario.length == 0 && (
          <Table striped bordered hover variant="dark">
            <thead>
              <tr>
                <th>Perfil</th>
                <th>Costo</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Agremiados</td>
                <td>{evento.precio_agremiados}</td>
              </tr>
              <tr>
                <td>No Agremiados</td>
                <td>{evento.precio_no_agremiados}</td>
              </tr>
            </tbody>
          </Table>
        )}
        {tarifario.length > 0 && (
          <Container>
            <Row>
              {tarifario.map((tarifa, indice) => (
                <Col xs={12} md={12} lg={12} key={indice}>
                  <Row>
                    <h5>
                      <strong>{tarifa.tarifa}</strong>
                    </h5>
                    <Table responsive bordered hover variant="dark">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Participantes</th>
                          <th>Precio</th>
                          <th>Beneficio</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tarifa.costos.map((costo, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{costo.descripcion}</td>
                            <td>{costo.precio}</td>
                            <td style={{ fontStyle: "oblique" }}>
                              {costo.adicional}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Row>
                </Col>
              ))}
            </Row>
          </Container>
        )}
      </Row>
      {evento.planos != null && (
        <Row>
          <Container fluid>
            <Row
              style={{
                padding: "2%",
                textAlign: "center",
                borderTop: "5px solid #003188",
                borderBottom: "5px solid #003188",
                backgroundColor: "rgb(230, 230, 230)",
              }}
            >
              <h1>
                <strong>PLANOS</strong>
              </h1>
            </Row>
            <Row style={{ padding: "5%" }}>
              <section>
                <img
                  src={evento.planos}
                  style={{ width: "75%" }}
                  alt="planos"
                  loading="lazy"
                />
              </section>
            </Row>
          </Container>
        </Row>
      )}
      {ejes.length > 0 && (
        <Row>
          <Container fluid>
            <Row
              style={{
                padding: "2%",
                textAlign: "center",
                borderTop: "5px solid #003188",
                borderBottom: "5px solid #003188",
                backgroundColor: "rgb(230, 230, 230)",
              }}
            >
              <h1>
                <strong>TEMARIO PRELIMINAR</strong>
              </h1>
            </Row>
            {ejes.map((actual, index) => (
              <Row style={{ padding: "5%" }} key={"temario-" + index}>
                <Col xs={12} md={12} lg={4} style={{ textAlign: "right" }}>
                  <p>
                    <strong>{actual.descripcion}</strong>
                  </p>
                </Col>
                <Col
                  xs={12}
                  md={12}
                  lg={8}
                  style={{ borderLeft: "5px solid #003188", textAlign: "left" }}
                >
                  <Temario eje={actual.UniqueID} />
                </Col>
              </Row>
            ))}
          </Container>
        </Row>
      )}
      {patrocinadores.length > 0 && (
        <Row id="patrocinadores">
          <Patrocinadores patrocinadores={patrocinadores} />
        </Row>
      )}
    </Container>
  );
};

export default Inscripciones;
