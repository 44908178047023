import React, { useEffect, useState } from 'react';
import { Row, Spinner, Col, Container, Form, Alert, Button } from "react-bootstrap";
import axios from 'axios';
const CargaMasiva = (props) => {
    const [contador, setContador] = useState(0);
    const [selectedFile, setSelectedFile] = useState(null);
    const [formulario, setFormulario] = useState('block');
    const [cargando, setCargando] = useState('none');
    const [exito, setExito] = useState('none');
    const [error, setError] = useState('none');
    const [mensaje, setMensaje] = useState('');
    useEffect(() => {

    }, [contador]);
    const atras = () => {
        setFormulario('block');
        setCargando('none');
        setExito('none');
        setError('none');
        setContador(contador + 1);
    }
    const onChange = (evt) => {
        switch (evt.target.name) {
            case "file":
                setSelectedFile(evt.target.files[0]);
                break;
        }
        setContador(contador + 1);
    }
    const cargar = () => {
        setFormulario('none');
        setCargando('block');
        setExito('none');
        setError('none');
        setMensaje('Procesando información...')
        setContador(contador + 1);
    }
    const operacionExito = (log) => {
        setFormulario('none');
        setCargando('none');
        setExito('block');
        setError('none');
        setMensaje(log)
        setContador(contador + 1);
    }
    const operacionError = (log) => {
        setFormulario('none');
        setCargando('none');
        setExito('none');
        setError('block');
        setMensaje(log)
        setContador(contador + 1);
    }
    const submit = () => {
        if (selectedFile != null) {
            cargar();
            const data = new FormData();
            data.append('file', selectedFile);
            axios.post(process.env.REACT_APP_DEFAULT_URL + "/api/files/upload/inscripcion/memory", data, {}).then(res => {
                if (res.data.codigo == 200) {
                    operacionExito(`Archivo procesado exitosamente, se han cargado ${res.data.data.data.length} participantes`);
                    props.cargarParticipantes(res.data.data.data);
                } else {
                    operacionError(res.data.ret);
                }
            });
            Array.from(document.querySelectorAll("input")).forEach(
                input => (input.value = "")
            );
            setSelectedFile(null);
            setContador(contador + 1);
        } else {
            operacionError('Por favor ingresa un archivo para procesar');
        }
    }
    return (
        <Container fluid>
            <Row style={{ padding: '2%' }}>
                <Container>
                    <Row>
                        <Col xs={12} md={12} lg={6} style={{ marginTop: '25px' }}>
                            <h4 style={{ textAlign: 'left' }}><strong>Subir participantes por medio de carga masiva</strong></h4>
                        </Col>
                        <Col xs={12} md={12} lg={6} style={{ marginTop: '25px' }}>
                            <Button variant='outline-dark' disabled={props.block} href="https://test-escuelabancaria.s3.us-east-2.amazonaws.com/Medios+General/MUESTRA+FORMULARIO+DE+INSCRIPCION.xlsx" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                            </svg>&nbsp;&nbsp;&nbsp;Descargar plantilla de excel para carga masiva</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12} lg={4} style={{ marginTop: '25px' }}>
                            <Form.Group controlId="formFileMultiple" style={{}}>
                                <Form.Control type="file" onChange={onChange} name="file" disabled={props.block} />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={12} lg={4} style={{ marginTop: '25px' }}>
                            <Container fluid>
                                <Row>
                                    <Col xs={12} md={12} lg={4}>
                                        <Spinner animation="border" style={{ display: cargando }} />
                                        <svg style={{ display: exito }} xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                        </svg>
                                        <svg style={{ display: error }} xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-exclamation-octagon-fill" viewBox="0 0 16 16">
                                            <path d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                        </svg>
                                    </Col>
                                    <Col xs={12} md={12} lg={8}>
                                        <Button onClick={submit} variant='outline-dark' disabled={props.block} >Subir participantes</Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col xs={12} md={12} lg={4} style={{ marginTop: '25px' }}>
                        </Col>
                    </Row>
                </Container>
            </Row>
        </Container>
    )
}

export default CargaMasiva