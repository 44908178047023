import React from 'react'
import Modal from 'react-bootstrap/Modal';
const ModalAgenda = (props) => {
    return (
        <Modal
            {...props}
            size="xl"
            centered
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <iframe src={props.agenda} style={{ width: '100%', height:'80vh' }} alt='agenda-imagen' />
            </Modal.Body>
        </Modal>)
}

export default ModalAgenda