import React from 'react'
import { Row, Col, Card } from "react-bootstrap";
import './style.css';
const RowDetail = (props) => {
    return (
        <Row className='row-agenda'>
            {props.eventos.map((actual, index) => <Col xs={12} md={12} lg={3} key={index} className='col-agenda'>
                <Card className='card-agenda'>
                    <Card.Header style={{ borderRadius: '50px' }}>
                        <Card.Title>{actual.dia}</Card.Title>
                        <Card.Subtitle>Inicia</Card.Subtitle>
                    </Card.Header>
                    <Card.Body>
                        <h5>{actual.tipo}</h5>
                        <Card.Text>{actual.Nombre}</Card.Text>
                        <Card.Text>{actual.Descripcion}</Card.Text>
                    </Card.Body>
                </Card>
            </Col>)}
        </Row>
    )
}

export default RowDetail