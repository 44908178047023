import React, { useState, useEffect } from 'react'
import { Card, Row, Col, Button, Container } from 'react-bootstrap';
const ProximosCongresos = () => {
    const [contador] = useState(0);
    const [congresos, setCongresos] = useState([]);
    const [portadaCongresos, setPortadaCongresos] = useState('');
    useEffect(() => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/congresos/ebg-portal', { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    console.log(data.rows)
                    setCongresos(data.rows);
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/congresos/info', { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setPortadaCongresos(data.imagen);
                });
        }
        fetchData();
    }, [contador]);

    return (
        <Container fluid style={{ color: 'black' }}>
            <Row style={{ padding: '3%', display: 'flex', alignItems: 'center' }}>
                <Col xs={12} md={12} lg={6} style={{ marginTop: '3px' }}>
                    <img loading="lazy" src='https://escuela-bancaria.s3.us-east-2.amazonaws.com/Logotipos+Finales+ABG+2022-01.png' style={{ width: '100%' }} alt='logos' />
                </Col>
                <Col xs={12} md={12} lg={6} style={{ display: 'grid', alignItems: 'center', borderRadius: '35px', marginTop: '30px' }}>
                    <Card>
                        <Card.Img loading="lazy" variant="top" src={portadaCongresos} style={{ width: '75%', alignSelf: 'center' }} />
                        <Card.Body>
                            <Card.Title><h3>Calendarización de congresos</h3></Card.Title>
                        </Card.Body>
                    </Card>
                </Col>
                <Row>
                    {congresos.map((congreso) =>
                        <Col xs={12} md={12} lg={6}>
                            <Card>
                                <Card.Img loading="lazy" variant="top" src={congreso.imagen} style={{ width: '75%', alignSelf: 'center' }}></Card.Img>
                                <Card.Body>
                                    <Card.Title>{congreso.Nombre}</Card.Title>
                                    <Card.Subtitle style={{ marginBottom: '15px' }}>{congreso.Fecha_Inicio}</Card.Subtitle>
                                    {(congreso.disponible === 1 && congreso.link_alternativo == null) && <Button variant='outline-dark' style={{ marginBottom: '15px' }} href={"/inscripcion/" + congreso.hash} ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pen-fill" viewBox="0 0 16 16">
                                        <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001z" />
                                    </svg>&nbsp;&nbsp;&nbsp;&nbsp;Inscribirme</Button>}
                                    {(congreso.link_alternativo != null) && <Button variant='outline-dark' style={{ marginBottom: '15px' }} href={congreso.link_alternativo} target="_blank" ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pen-fill" viewBox="0 0 16 16">
                                        <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001z" />
                                    </svg>&nbsp;&nbsp;&nbsp;&nbsp;Inscribirme</Button>}
                                    {congreso.diff > 1 && <Card.Text><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-hourglass-top" viewBox="0 0 16 16">
                                        <path d="M2 14.5a.5.5 0 0 0 .5.5h11a.5.5 0 1 0 0-1h-1v-1a4.5 4.5 0 0 0-2.557-4.06c-.29-.139-.443-.377-.443-.59v-.7c0-.213.154-.451.443-.59A4.5 4.5 0 0 0 12.5 3V2h1a.5.5 0 0 0 0-1h-11a.5.5 0 0 0 0 1h1v1a4.5 4.5 0 0 0 2.557 4.06c.29.139.443.377.443.59v.7c0 .213-.154.451-.443.59A4.5 4.5 0 0 0 3.5 13v1h-1a.5.5 0 0 0-.5.5zm2.5-.5v-1a3.5 3.5 0 0 1 1.989-3.158c.533-.256 1.011-.79 1.011-1.491v-.702s.18.101.5.101.5-.1.5-.1v.7c0 .701.478 1.236 1.011 1.492A3.5 3.5 0 0 1 11.5 13v1h-7z" />
                                    </svg>&nbsp;&nbsp;&nbsp;&nbsp;Faltan {congreso.diff} días</Card.Text>}
                                    {congreso.diff === 1 && <Card.Text><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-hourglass-split" viewBox="0 0 16 16">
                                        <path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z" />
                                    </svg>&nbsp;&nbsp;&nbsp;&nbsp;¡Es mañana!</Card.Text>}
                                    {congreso.diff === 0 && <Card.Text><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-hourglass-bottom" viewBox="0 0 16 16">
                                        <path d="M2 1.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1-.5-.5zm2.5.5v1a3.5 3.5 0 0 0 1.989 3.158c.533.256 1.011.791 1.011 1.491v.702s.18.149.5.149.5-.15.5-.15v-.7c0-.701.478-1.236 1.011-1.492A3.5 3.5 0 0 0 11.5 3V2h-7z" />
                                    </svg>&nbsp;&nbsp;&nbsp;&nbsp;¡Es hoy!</Card.Text>}
                                </Card.Body>
                            </Card>
                        </Col>
                    )}
                </Row>
            </Row>
        </Container>
    )
}

export default ProximosCongresos