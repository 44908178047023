import React from 'react'
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

const CongresosAnteriores = () => {
    return (
        <Container>
            <Row>
                <Col xs={12} md={6} lg={6} style = {{padding:'5%'}}>
                    <Card>
                        <Card.Img src='https://escuela-bancaria.s3.us-east-2.amazonaws.com/FINAL-2Arte-Invitacion-7ma-feria-2021.jpg'></Card.Img>
                        <Card.Body>
                            <Button variant='outline-secondary' href='https://ebg.edu.gt/congresos-virtuales/feria-bancaria-2021/' target='_blank'>Ingresar a evento virtual</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={6} lg={6} style = {{padding:'5%'}}>
                    <Card>
                        <Card.Img src='https://escuela-bancaria.s3.us-east-2.amazonaws.com/Invitacion+II+FERIA+(3).jpg'></Card.Img>
                        <Card.Body>
                            <Button variant='outline-secondary' href='https://ebg.edu.gt/congresos-virtuales/feria-bancaria-2022/' target='_blank'>Ingresar a evento virtual</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6} lg={6} style = {{padding:'5%'}}>
                    <Card>
                        <Card.Img src='https://escuela-bancaria.s3.us-east-2.amazonaws.com/VIRTUAL-Save-the-date-C%26C-2022+(5).jpg'></Card.Img>
                        <Card.Body>
                            <Button variant='outline-secondary' href='https://ebg.edu.gt/congresos-virtuales/coretic-coreseb-2022/' target='_blank'>Ingresar a evento virtual</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={6} lg={6} style = {{padding:'5%'}}>
                    <Card>
                        <Card.Img src='https://escuela-bancaria.s3.us-east-2.amazonaws.com/Save-the-date-CORERIF-2022.jpg'></Card.Img>
                        <Card.Body>
                            <Button variant='outline-secondary' href='https://ebg.edu.gt/congresos-virtuales/corerif-2022/' target='_blank'>Ingresar a evento virtual</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default CongresosAnteriores