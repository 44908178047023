import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import "./style.css";
const helpers = require("../helpers/helpers");
const EducacionFormacion = () => {
  const [contador] = useState(0);
  const [imgAgenda, setImgAgenda] = useState("");
  const [titulo, setTitulo] = useState("");
  const [catalogos, setCatalogos] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const filteredCatalogos = catalogos.filter((tema) =>
    tema.Nombre.toLowerCase().includes(searchTerm.toLowerCase())
  );
  useEffect(() => {
    helpers
      .getData("/api/agenda/info")
      .then((data) => {
        setImgAgenda(data.data.imagen);
        setTitulo(data.data.titulo);
        helpers
          .getData("/api/catalogo-tema/read")
          .then((datos) => {
            setCatalogos(datos.data.salida);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [contador]);

  return (
    <Container fluid>
      <Row
        style={{
          backgroundColor: "rgb(230, 230, 230)",
          padding: "2%",
          textAlign: "left",
          borderTop: "5px solid #003188",
          borderBottom: "5px solid #003188",
        }}
      >
        <Col xs={12} md={12} lg={2} style={{ padding: "1%" }}>
          <img src={imgAgenda} style={{ width: "100%", borderRadius: "2em" }} />
        </Col>
        <Col xs={12} md={12} lg={10} style={{ padding: "1%" }}>
          <h1>
            <strong>EDUCACIÓN Y FORMACIÓN</strong>
          </h1>
          <h2>
            <strong>OFERTA FORMATIVA</strong>
          </h2>
          <h3><strong>{catalogos.length}</strong> catálogos disponibles</h3>
        </Col>
      </Row>
      <Row className="mt-5" style={{ padding: "2%" }}>
        <Form className="mb-3">
          <Form.Group controlId="search">
            <Form.Control
              type="text"
              placeholder="Busca el catálogo por nombre"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Row>
      <Row>
        <Container fluid>
          <Row>
            {filteredCatalogos &&
              filteredCatalogos.map((catalogo, indice) => (
                <Col
                  xs={12}
                  md={6}
                  lg={4}
                  key={catalogo.UniqueID}
                  className="custom-col"
                >
                  <Card className="custom-card">
                    <Card.Img variant="top" src={catalogo.foto} />
                    <Card.Body className="d-flex flex-column">
                      <Card.Title>{catalogo.Nombre}</Card.Title>
                      <Card.Text>{catalogo.descripcion}</Card.Text>
                      <div className="mt-auto">
                        <Button
                          className="custom-button"
                          href={
                            "/educacion-y-formacion/catalogo/" +
                            catalogo.UniqueID
                          }
                        >
                          Más información
                        </Button>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
          </Row>
        </Container>
      </Row>
    </Container>
  );
};

export default EducacionFormacion;
