import React from 'react'
import { Container, Row } from 'react-bootstrap';
import Agenda from './Agenda';
const Jornadas = (props) => {

    return (
        <Container fluid>
            {props.jornadas.map((actual, index) => <Row key = {'jornadas-'+index}>
                <Container fluid>
                    <Row style={{ backgroundColor: 'rgb(230, 230, 230)', padding: '2%', textAlign: 'left', borderTop: '5px solid #003188' }}>
                        <h1 style={{ fontWeight: '800' }}>{actual.fecha}</h1>
                        <h2>{actual.descripcion}</h2>
                        <h3>{actual.modalidad}</h3>
                    </Row>
                    <Row>
                        <Agenda jornada={actual} />
                    </Row>
                </Container>
            </Row>)}
        </Container>
    )
}

export default Jornadas