import React, { useState, useEffect } from 'react'
import { Card, Row, Col, Button, Container } from 'react-bootstrap';
import { useParams } from "react-router-dom";

import "./style.css"
const InscribeteEnLineaAsesor = () => {
    const { hashAsesor } = useParams();

    const [contador] = useState(0);
    const [agenda, setAgenda] = useState([]);
    const [imgAgenda, setImgAgenda] = useState('');
    const [titulo, setTitulo] = useState('');
    const [asesorInfo, setAsesorInfo] = useState({});

    useEffect(() => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/inscripciones/abiertas', { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setAgenda(data.rows);
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read_hash/asesor/' + hashAsesor, { cache: "no-cache", credentials: 'include' })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setAsesorInfo(data.rows[0]);
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/agenda/info', { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setImgAgenda(data.imagen);
                    setTitulo(data.titulo);
                });
        }
        fetchData();
    }, [contador]);
    return (
        <Container fluid >
            <Row style={{ backgroundColor: 'rgb(230, 230, 230)', padding: '2%', textAlign: 'left', borderTop: '5px solid #003188', borderBottom: '5px solid #003188' }}>
                <Col xs={12} md={12} lg={2} style={{ padding: '1%' }}>
                    <img src={imgAgenda} style={{ width: '100%', borderRadius: '2em' }} />
                </Col>
                <Col xs={12} md={12} lg={10} style={{ padding: '1%' }}>
                    <h1 style={{ fontWeight: '900' }}>INSCRIBETE EN LÍNEA</h1>
                    <h2 style={{ fontWeight: '600', fontStyle: 'italic' }}>{titulo}</h2>
                    {asesorInfo!=null&&<p><strong>Asesor Comercial: </strong>{asesorInfo.Nombres + ' ' + asesorInfo.Apellidos}<br/><strong>Correo electrónico: </strong>{asesorInfo.email}<br/><strong>WhatsApp: </strong>{asesorInfo.whatsapp}<br/><strong>Teléfono: </strong>{asesorInfo.telefono_institucion}</p>}
                </Col>
            </Row>
            {
                agenda && agenda.map((evento, indice) =>
                    <Row key={indice} style={{ padding: '2%', textAlign: 'left', borderBottom: '5px solid rgb(230, 230, 230)' }}>
                        <Col xs={12} md={12} lg={2} style={{ marginTop: '25px', borderRight: '5px solid rgb(230, 230, 230)', textAlign: 'right' }} >
                            <h1 style={{ fontWeight: '900' }}>{evento.fechaInicioMonth.toUpperCase()}</h1>
                            <h4 >{evento.tipo}</h4>
                        </Col>
                        <Col xs={12} md={12} lg={10} style={{ marginTop: '25px' }}>
                            <Container>
                                <Row>
                                    <Col xs={12} md={12} lg={2} >
                                        <img loading="lazy" src={evento.flier} style={{ width: '100%' }} />
                                    </Col>
                                    <Col xs={12} md={12} lg={10}>
                                        <h1 style={{ fontWeight: '600' }}>{evento.Nombre}</h1>
                                        <Button variant='outline-secondary' href={'/inscripcion/' + evento.hash+'/'+asesorInfo.hash}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle-fill" viewBox="0 0 16 16">
                                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                                        </svg>&nbsp;&nbsp;&nbsp;&nbsp;Más información</Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                )
            }
        </Container >
    )
}

export default InscribeteEnLineaAsesor