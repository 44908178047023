import React, { useState, useEffect } from 'react';
import { InputGroup, Row, Button, Col, Form, Container, Spinner } from "react-bootstrap";
const PasarelaForm = () => {
    const [contador, setContador] = useState(0);
    const [validated, setValidated] = useState(false);
    const [nombre, setNombre] = useState('');
    const [apellido, setApellido] = useState('');
    const [email, setEmail] = useState('');
    const [telefono, setTelefono] = useState('');
    const [tarjeta, setTarjeta] = useState(0);
    const [cvv, setCvv] = useState(0);
    const [fechaExp, setFechaExp] = useState('');
    const [tipo, setTipo] = useState('');
    const [total, setTotal] = useState(0.00);
    const [formulario, setFormulario] = useState('block');
    const [cargando, setCargando] = useState('none');
    const [exito, setExito] = useState('none');
    const [error, setError] = useState('none');
    const [concepto, setConcepto] = useState('');
    useEffect(() => {

    }, [contador]);

    const onSubmit = (evt) => {
        const form = evt.currentTarget;
        if (form.checkValidity() === false) {
            evt.preventDefault();
            evt.stopPropagation();
        } else {
            cargar();
            evt.preventDefault();
            const info = { email: process.env.REACT_APP_PASARELA_PAGO_USR, password: process.env.REACT_APP_PASARELA_PAGO_PWD };
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(info)
            };
            fetch(process.env.REACT_APP_PASARELA_PAGO_API_URL + '/api/auth/login', requestOptions)
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    fetch("https://api.ipify.org?format=json")
                        .then((respuestaIp) => {
                            return respuestaIp.json();
                        })
                        .then(datos_ip => {
                            let monthvalue = fechaExp.split('-')[1] + '/' + fechaExp.split('-')[0];
                            const info_pay = { order_id: Date.now(), firstName: nombre, lastName: apellido, address: 'Diagonal 6, 10-01 zona 10, Centro Gerencial Las Margaritas, Torre II, 5º. Nivel, Oficina 502 “A” Guatemala.', city: 'Guatemala', state: 'Guatemala', postalCode: '01010', country: 'Guatemala', email: email, phoneNumber: telefono, ipAddress: datos_ip.ip, ccexp: monthvalue, ccnumber: parseInt(tarjeta), cvv: parseInt(cvv), currency: tipo, total: parseFloat(total) };
                            const requestOptions_pay = {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${data.access_token}`
                                },
                                body: JSON.stringify(info_pay)
                            };
                            fetch(process.env.REACT_APP_PASARELA_PAGO_API_URL + '/api/pay/payment', requestOptions_pay)
                                .then((response) => {
                                    return response.json();
                                })
                                .then(datos => {
                                    const requestOptions_logout = {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Authorization': `Bearer ${data.access_token}`
                                        },
                                    };
                                    fetch(process.env.REACT_APP_PASARELA_PAGO_API_URL + '/api/auth/logout', requestOptions_logout)
                                        .then((respuesta) => {
                                            return respuesta.json();
                                        })
                                        .then(datosLogout => {
                                            if (datos.result == true) {
                                                operacionExito();
                                            } else {
                                                operacionError();
                                            }
                                        });
                                });
                        });

                });
            //operacionExito();
        }
        setValidated(true);
        setContador(contador + 1);
    }
    const onChange = (evt) => {
        if (evt.target.name == 'nombre') {
            setNombre(evt.target.value);
        } else if (evt.target.name == 'apellido') {
            setApellido(evt.target.value);
        } else if (evt.target.name == 'telefono') {
            setTelefono(evt.target.value);
        } else if (evt.target.name == 'tarjeta') {
            setTarjeta(evt.target.value);
        } else if (evt.target.name == 'cvv') {
            setCvv(evt.target.value);
        } else if (evt.target.name == 'exp') {
            setFechaExp(evt.target.value);
        } else if (evt.target.name == 'curr') {
            setTipo(evt.target.value);
        } else if (evt.target.name == 'total') {
            setTotal(evt.target.value);
        } else if (evt.target.name == 'email') {
            setEmail(evt.target.value);
        } else if (evt.target.name == 'concepto') {
            setConcepto(evt.target.value);
        }
    }
    const operacionExito = () => {
        const info = { email: email, total: tipo + ' ' + parseFloat(total), nombres: nombre + ' ' + apellido, tarjeta: 'tarjeta finalizada en ' + tarjeta.toString().substr(tarjeta.toString().length - 4), concepto: concepto, telefono: telefono };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/email/send-mail-recepcion-pago', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                savePayment();
            });
        //savePayment();

    }
    const savePayment = () => {
        const info = {
            nombres: nombre,
            apellidos: apellido,
            email: email,
            tel: telefono,
            tarjeta: 'tarjeta finalizada en ' + tarjeta.toString().substr(tarjeta.toString().length - 4),
            descripcion: concepto,
            moneda: tipo,
            total: total
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/pago-no-cobro/create', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setFormulario('none');
                setError('none');
                setExito('block');
                setCargando('none');
                setContador(contador + 1);
            });
    }
    const operacionError = () => {
        setFormulario('none');
        setError('block');
        setExito('none');
        setCargando('none');
        setContador(contador + 1);
    }
    const cargar = () => {
        setFormulario('none');
        setError('none');
        setExito('none');
        setCargando('block');
        setContador(contador + 1);
    }
    const retry = () => {
        setFormulario('block');
        setError('none');
        setExito('none');
        setCargando('none');
        setContador(contador + 1);
    }
    const goBack = () => {
        setFormulario('block');
        setError('none');
        setExito('none');
        setCargando('none');
        setNombre('');
        setApellido('');
        setEmail('');
        setTelefono('');
        setTarjeta(0);
        setCvv(0);
        setFechaExp('');
        setTipo('');
        setTotal(0.00);
        setConcepto('');
        setContador(contador + 1);
    }
    return (
        <Container fluid>
            <Row style={{ paddingTop: '5%', paddingBottom: '5%', marginBottom: '10%', display: formulario }}>
                <Form noValidate validated={validated} onSubmit={onSubmit}>
                    <Container>
                        <Row style={{ marginBottom: '25px' }}>
                            <h1>Información del comprador</h1>
                        </Row>
                        <Row>
                            <Col xs={12} md={12} lg={3} style={{ marginTop: '25px' }}>
                                <Form.Group controlId="nombre">
                                    <Form.Label>Nombres</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Por favor, ingrese sus nombres"
                                        name='nombre'
                                        onChange={onChange}
                                        value={nombre}
                                    />
                                    <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                    <Form.Control.Feedback type='invalid'>Por favor, ingrese sus nombres</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={12} lg={3} style={{ marginTop: '25px' }}>
                                <Form.Group controlId="apellido">
                                    <Form.Label>Apellidos</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Por favor, ingrese sus apellidos"
                                        name='apellido'
                                        onChange={onChange}
                                        value={apellido}
                                    />
                                    <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                    <Form.Control.Feedback type='invalid'>Por favor, ingrese sus apellidos</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={12} lg={3} style={{ marginTop: '25px' }}>
                                <Form.Group controlId="email">
                                    <Form.Label>Correo electrónico</Form.Label>
                                    <Form.Control
                                        required
                                        type="email"
                                        placeholder="Por favor, ingrese su email"
                                        name='email'
                                        onChange={onChange}
                                        value={email}
                                    />
                                    <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                    <Form.Control.Feedback type='invalid'>Por favor, ingrese su email</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={12} lg={3} style={{ marginTop: '25px' }}>
                                <Form.Group controlId="telefono">
                                    <Form.Label>No. Teléfono</Form.Label>
                                    <Form.Control
                                        required
                                        type="tel"
                                        pattern="\d*"
                                        placeholder="Por favor, ingrese su telefono"
                                        name='telefono'
                                        onChange={onChange}
                                        value={telefono}
                                    />
                                    <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                    <Form.Control.Feedback type='invalid'>Por favor, ingrese su telefono</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '25px' }}>
                            <h1>Información de pago</h1>
                        </Row>
                        <Row>
                            <Col xs={12} md={12} lg={8} style={{ marginTop: '25px' }}>
                                <Form.Group controlId="tarjeta">
                                    <Form.Label>No. Tarjeta</Form.Label>
                                    <InputGroup hasValidation>
                                        <InputGroup.Text><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-credit-card" viewBox="0 0 16 16">
                                            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z" />
                                            <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z" />
                                        </svg></InputGroup.Text>
                                        <Form.Control
                                            type="tel"
                                            inputMode='numeric'
                                            pattern="\d*"
                                            autoComplete='cc-number'
                                            maxLength={19}
                                            placeholder="xxxx xxxx xxxx xxxx"
                                            aria-describedby="tarjeta"
                                            required
                                            name='tarjeta'
                                            onChange={onChange}
                                            value={tarjeta}
                                        />
                                        <Form.Control.Feedback>
                                            Ok
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            Por favor, ingrese su numero de tarjeta
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={12} lg={4} style={{ marginTop: '25px' }}>
                                <Form.Group controlId="cvv">
                                    <Form.Label>CVV</Form.Label>
                                    <Form.Control
                                        required
                                        type="tel"
                                        pattern="\d*"
                                        placeholder="Por favor, ingrese el cvv de su tarjeta"
                                        name='cvv'
                                        onChange={onChange}
                                        value={cvv}
                                    />
                                    <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                    <Form.Control.Feedback type='invalid'>Por favor, ingrese el cvv de su tarjeta</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '25px' }}>
                            <Col xs={12} md={12} lg={4} style={{ marginTop: '25px' }}>
                                <Form.Group controlId="exp">
                                    <Form.Label>Fecha de expiración</Form.Label>
                                    <Form.Control
                                        required
                                        type="month"
                                        maxLength={7}
                                        name='exp'
                                        onChange={onChange}
                                        value={fechaExp}
                                    />
                                    <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                    <Form.Control.Feedback type='invalid'>Por favor, ingrese la fecha de expiración</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={12} lg={4} style={{ marginTop: '25px' }}>
                                <Form.Group controlId="curr">
                                    <Form.Label>Tipo moneda</Form.Label>
                                    <Form.Select required onChange={onChange} name='curr' value={tipo}>
                                        <option value=''>Seleccione la moneda</option>
                                        <option value="GTQ">GTQ</option>
                                        <option value="USD">USD</option>
                                    </Form.Select>
                                    <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                    <Form.Control.Feedback type='invalid'>Por favor, seleccione el tipo de moneda</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={12} lg={4} style={{ marginTop: '25px' }}>
                                <Form.Group controlId="total">
                                    <Form.Label>Total a pagar</Form.Label>
                                    <InputGroup hasValidation>
                                        <InputGroup.Text>{tipo}</InputGroup.Text>
                                        <Form.Control
                                            type="number"
                                            step='0.01'
                                            required
                                            name='total'
                                            onChange={onChange}
                                            value={total}
                                            disabled={tipo == ''}
                                        />
                                        <Form.Control.Feedback>
                                            Ok
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            Por favor, ingrese el total a pagar
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '25px' }}>
                            <Form.Group controlId="concepto">
                                <Form.Label>Concepto del pago</Form.Label>
                                <Form.Control as="textarea" placeholder="Ingrese el concepto del pago" rows={3} required style={{ resize: 'none' }} name="concepto" onChange={onChange} value={concepto} />
                                <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                <Form.Control.Feedback type='invalid'>Por favor, ingrese el concepto del pago</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row style={{ marginTop: '25px' }}>
                            <Button variant='outline-secondary' type='submit'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cash-coin" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z" />
                                <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z" />
                                <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z" />
                                <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z" />
                            </svg>&nbsp;&nbsp;&nbsp;&nbsp;Realizar pago</Button>
                        </Row>
                        <Row style={{ marginTop: '25px' }}>
                            <Button variant='outline-secondary' onClick={goBack}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-octagon-fill" viewBox="0 0 16 16">
                                <path d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146zm-6.106 4.5L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z" />
                            </svg>&nbsp;&nbsp;&nbsp;&nbsp;Cancelar</Button>
                        </Row>
                    </Container>
                </Form>
            </Row>
            <Row style={{ paddingTop: '5%', paddingBottom: '5%', marginBottom: '10%', display: cargando }}>
                <Container style={{ display: 'grid', placeItems: 'center' }}>
                    <Row>
                        <Spinner animation="border" size='xxl' />
                    </Row>
                    <Row style={{ marginTop: '50px' }}>
                        <h3>Procesando operación</h3>
                        <p>Esto puede demorar unos minutos...</p>
                    </Row>
                </Container>
            </Row>
            <Row style={{ paddingTop: '5%', paddingBottom: '5%', marginBottom: '10%', display: exito }}>
                <Container style={{ display: 'grid', placeItems: 'center' }}>
                    <Row>
                        <svg xmlns="http://www.w3.org/2000/svg" width="50%" height="50%" fill="currentColor" className="bi bi-check-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                        </svg>
                    </Row>
                    <Row style={{ marginTop: '50px' }}>
                        <h3>Pago procesado con éxito</h3>
                        <p>Le llegará un mail con más información</p>
                    </Row>
                    <Row style={{ marginTop: '50px' }}>
                        <Button variant='outline-success' onClick={goBack}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-credit-card-2-front-fill" viewBox="0 0 16 16">
                            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2.5 1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-2zm0 3a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm3 0a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm3 0a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm3 0a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z" />
                        </svg>&nbsp;&nbsp;&nbsp;&nbsp;Realizar otro pago</Button>
                    </Row>
                </Container>
            </Row>
            <Row style={{ paddingTop: '5%', paddingBottom: '5%', marginBottom: '10%', display: error }}>
                <Container style={{ display: 'grid', placeItems: 'center' }}>
                    <Row>
                        <svg xmlns="http://www.w3.org/2000/svg" width="50%" height="50%" fill="currentColor" className="bi bi-bug" viewBox="0 0 16 16">
                            <path d="M4.355.522a.5.5 0 0 1 .623.333l.291.956A4.979 4.979 0 0 1 8 1c1.007 0 1.946.298 2.731.811l.29-.956a.5.5 0 1 1 .957.29l-.41 1.352A4.985 4.985 0 0 1 13 6h.5a.5.5 0 0 0 .5-.5V5a.5.5 0 0 1 1 0v.5A1.5 1.5 0 0 1 13.5 7H13v1h1.5a.5.5 0 0 1 0 1H13v1h.5a1.5 1.5 0 0 1 1.5 1.5v.5a.5.5 0 1 1-1 0v-.5a.5.5 0 0 0-.5-.5H13a5 5 0 0 1-10 0h-.5a.5.5 0 0 0-.5.5v.5a.5.5 0 1 1-1 0v-.5A1.5 1.5 0 0 1 2.5 10H3V9H1.5a.5.5 0 0 1 0-1H3V7h-.5A1.5 1.5 0 0 1 1 5.5V5a.5.5 0 0 1 1 0v.5a.5.5 0 0 0 .5.5H3c0-1.364.547-2.601 1.432-3.503l-.41-1.352a.5.5 0 0 1 .333-.623zM4 7v4a4 4 0 0 0 3.5 3.97V7H4zm4.5 0v7.97A4 4 0 0 0 12 11V7H8.5zM12 6a3.989 3.989 0 0 0-1.334-2.982A3.983 3.983 0 0 0 8 2a3.983 3.983 0 0 0-2.667 1.018A3.989 3.989 0 0 0 4 6h8z" />
                        </svg>
                    </Row>
                    <Row style={{ marginTop: '50px' }}>
                        <h3>Ha ocurrido un error al procesar tu pago</h3>
                        <p>Por favor, revisa tu información</p>
                    </Row>
                    <Row style={{ marginTop: '50px' }}>
                        <Button variant='outline-danger' onClick={retry}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-credit-card-2-front-fill" viewBox="0 0 16 16">
                            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2.5 1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-2zm0 3a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm3 0a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm3 0a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm3 0a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z" />
                        </svg>&nbsp;&nbsp;&nbsp;&nbsp;Intentar de nuevo</Button>
                    </Row>
                </Container>
            </Row>
        </Container>)
}

export default PasarelaForm