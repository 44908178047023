import React, { useEffect, useState } from "react";

const Temario = (props) => {
  const [contador] = useState(0);
  const [temas, setTemas] = useState([]);
  useEffect(() => {
    fetch(
      process.env.REACT_APP_DEFAULT_URL + "/api/tema-temario/read/" + props.eje,
      { cache: "no-cache" }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setTemas(data.rows);
      });
  }, [contador]);

  return (
    <ol>
      {temas.map((actual, index) => (
        <li key={index}>{actual.descripcion}</li>
      ))}
    </ol>
  );
};

export default Temario;
