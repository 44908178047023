import React from 'react'
import { Modal, Container, Row } from 'react-bootstrap';
const Enfoque = (props) => {
  return (
    <Modal
      {...props}
      size="xl"
      centered
      scrollable
      backdrop='static'
    >
      <Modal.Header closeButton>
        Enfoque de la formación - educación de la EBG
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row style={{ textAlign: 'justify' }}>
            <p>
              El diseño de la formación por competencias implementado por la EBG, introduce una serie de cambios en el sistema tradicional de formación educación. En el sistema tradicional es importante demostrar que la persona, fue aprobada al final de un curso o programa, a través de exámenes teóricos, recibiendo un diploma de conclusión del respectivo curso.
              <br/><br/>
              En cambio, en el sistema de formación por competencias desarrollado por la EBG, se define claramente lo que la persona debe ser capaz de hacer, para desempeñar eficiente y eficazmente un trabajo, a través de un conjunto de estándares o normas de competencia, en los cuales se indica: que debe hacer, como lo debe hacer, con que herramientas, que actitudes o valores debe poseer y en que ámbitos o campos lo debe aplicar. Una norma equivale a una Unidad de Competencia.
              <br/><br/>
              Cada calificación profesional está constituida por un número de unidades de competencia, que representan los niveles que debe poseer una persona en el desempeño de las funciones de su trabajo. Los resultados de los individuos son evaluados en el desempeño de sus funciones, a través de evidencias de: desempeño, producto o de conocimiento. La  formación por competencias respeta el ritmo propio de aprendizaje de cada individuo, y en este sentido la evaluación y certificación son realizadas por unidades de competencia. Asimismo, para que se otorgue una certificación, a un trabajador, este debe ser capaz de demostrar que realiza, todas las unidades de competencias correspondientes a su nivel ocupacional o figura profesional, reconocida en el Sector. Ejemplo: Cajero o Receptor Pagador, Asesor de Negocios, Gerente de Agencias, Director o Gerente de un área, etc.
              <br/><br/>
              Para la obtención de una Certificación por Competencia de la EBG, formalmente reconocida en el medio laboral, el candidato no necesariamente tiene que prestar un examen escrito. La consecución del nivel de competencia es evaluado por la EBG, mediante observaciones en el lugar del trabajo, o incluyendo simulaciones prácticas, preguntas orales, pruebas escritas, proyectos y actividades prácticas resultados de un programa de formación; en realidad lo que cuenta en la evaluación por competencia de la EBG, es que la persona demuestre que es capaz de realizar de un modo satisfactorio sus actividades, con el nivel de competencia requerido; tanto a nivel técnico, básico (competencias duras) y genérico o transversal (competencias blandas).
            </p>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>)
}
export default Enfoque