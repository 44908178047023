import React from 'react'
import { useParams } from 'react-router-dom'
import Inscripciones from './Inscripciones';
const Inscripcion = () => {
    const { hash } = useParams();
    return (
        <Inscripciones
            hash={hash}
            hash_asesor={null}
            hash_descuento={null}
            lead = {null}
        />
    )
}

export default Inscripcion