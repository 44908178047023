import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Carousel, Button, Dropdown } from 'react-bootstrap';
import './Home.css'
import { motion } from 'framer-motion';
import MediaColumn from './MediaColumn';
import AboutRow from './AboutRow';
import ActivitiesRow from './ActivitiesRow';
import AgendaCarrusel from './AgendaCarrusel';

const Home = () => {
    const [contador] = useState(0);
    const [congresos, setCongresos] = useState([]);
    const [portadaCongresos, setPortadaCongresos] = useState('');
    const [index, setIndex] = useState(0);
    const [cambios, setCambios] = useState(0);
    useEffect(() => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/congresos/ebg-portal', { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setCongresos(data.rows);
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/congresos/info', { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setPortadaCongresos(data.imagen);
                });
        }
        fetchData();
    }, [contador]);
    useEffect(() => {

    }, [cambios]);
    
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
        setCambios(cambios+1);
    };
    return (
        <Container fluid>
            <Row style={{ display: 'flex', placeItems: 'center' }}>
                <MediaColumn />
                <Col xs={9} md={11} lg={6} style={{ marginTop: '25px' }}>
                    <motion.img
                        src={portadaCongresos}
                        alt='portada-congresos'
                        style={{ width: '100%', borderRadius: '50px' }}
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 1 }} />
                </Col>
                <Col xs={12} md={12} lg={5} style={{ marginTop: '25px' }}>
                    <Carousel
                        activeIndex={index}
                        onSelect={handleSelect}
                        variant="dark"
                        indicators={false}>
                        {congresos.map((congreso) =>
                            <Carousel.Item style={{ height: '100%' }} key={congreso.hash}>
                                <Container fluid style={{ height: '100%' }}>
                                    <Row>
                                        <Col xs={12} md={12} lg={6} style={{ marginTop: '15px' }}>
                                            <h3><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-calendar2-check-fill" viewBox="0 0 16 16">
                                                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zm9.954 3H2.545c-.3 0-.545.224-.545.5v1c0 .276.244.5.545.5h10.91c.3 0 .545-.224.545-.5v-1c0-.276-.244-.5-.546-.5zm-2.6 5.854a.5.5 0 0 0-.708-.708L7.5 10.793 6.354 9.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z" />
                                            </svg>&nbsp;&nbsp;&nbsp;&nbsp;{congreso.Fecha_Inicio}</h3>
                                        </Col>
                                    </Row>
                                    <Row style={{ display: 'grid', placeItems: 'center', padding: '2%' }}>
                                        <img src={congreso.imagen} alt={congreso.Nombre} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                    </Row>
                                    <Row>
                                        {congreso.disponible === 1 && congreso.link_alternativo==null && <Button href={"/inscripcion/" + congreso.hash} variant='outline-dark'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pen-fill" viewBox="0 0 16 16">
                                            <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001z" />
                                        </svg>&nbsp;&nbsp;&nbsp;&nbsp;Inscribirme</Button>}
                                    </Row>
                                    <Row>
                                        {congreso.link_alternativo!=null && <Button href={congreso.link_alternativo} target="_blank" variant='outline-dark'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pen-fill" viewBox="0 0 16 16">
                                            <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001z" />
                                        </svg>&nbsp;&nbsp;&nbsp;&nbsp;Inscribirme</Button>}
                                    </Row>
                                </Container>
                            </Carousel.Item>
                        )}
                    </Carousel>
                </Col>
            </Row>
            <AgendaCarrusel />
            <AboutRow />
            <ActivitiesRow />
        </Container >
    )
}

export default Home