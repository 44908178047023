import React from "react";
import { useParams } from "react-router-dom";
import Inscripciones from "./Inscripciones";
const InscripcionDescuento = () => {
  const { hash, hash_asesor, hash_descuento } = useParams();
  return (
    <Inscripciones
      hash={hash}
      hash_asesor={hash_asesor}
      hash_descuento={hash_descuento}
      lead={null}
      cortesia={false}
    />
  );
};

export default InscripcionDescuento;
