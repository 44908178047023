import React, { useEffect, useState } from "react";
import { Card, Row, Col, Button, Carousel, Container } from "react-bootstrap";
import "./Home.css";
import ModalAgenda from "./ModalAgenda";
const AgendaCarrusel = () => {
  const [contador] = useState(0);
  const [agenda, setAgenda] = useState([]);
  const [index, setIndex] = useState(0);
  const [imgAgenda, setImgAgenda] = useState("");
  const [titulo, setTitulo] = useState("");
  const [modalAgenda, setModalAgenda] = useState(false);
  const [agendaImagen, setAgendaImagen] = useState("");
  const [cambios, setCambios] = useState(0);
  useEffect(() => {
    async function fetchData() {
      fetch(
        process.env.REACT_APP_DEFAULT_URL +
          "/api/read/evento/inscripciones/abiertas",
        { cache: "no-cache" }
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          let agnd = [];
          let actual = [];
          data.rows.map((current, index) => {
            if (
              ((index + 1) % 3 === 0 && index > 0) ||
              index === data.rows.length - 1
            ) {
              actual.push(current);
              agnd.push(actual);
              actual = [];
            } else {
              actual.push(current);
            }
            return true;
          });
          setAgenda(agnd);
        });
      fetch(process.env.REACT_APP_DEFAULT_URL + "/api/agenda/info", {
        cache: "no-cache",
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setImgAgenda(data.imagen);
          setTitulo(data.titulo);
          setAgendaImagen(data.agenda);
        });
    }
    fetchData();
  }, [contador]);
  useEffect(() => {}, [cambios]);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
    setCambios(cambios + 1);
  };
  return (
    <Row
      style={{
        display: "flex",
        placeItems: "center",
        backgroundColor: "#EFEEEE",
      }}
    >
      <Container fluid>
        <Row
          style={{ marginTop: "20px", display: "flex", placeItems: "center" }}
        >
          <Col xs={12} md={12} lg={8} style={{ marginBottom: "25px" }}>
            <Carousel
              activeIndex={index}
              onSelect={handleSelect}
              variant="dark"
            >
              {agenda.map((actual, indice) => (
                <Carousel.Item style={{ height: "100%" }} key={indice}>
                  <Container style={{ height: "100%" }}>
                    <Row>
                      {actual.map((evento, index) => (
                        <Col
                          xs={12}
                          md={6}
                          lg={4}
                          style={{ marginBottom: "45px" }}
                          key={"evento-" + index}
                        >
                          <Card style={{ height: "100%" }}>
                            <Card.Img variant="top" src={evento.flier} />
                            <Card.Body>
                              <small className="text-muted">
                                {evento.Fecha_Inicio}
                              </small>
                            </Card.Body>
                            <Card.Footer>
                              {evento.link_alternativo == null && (
                                <Button
                                  variant="outline-primary"
                                  className="button-card"
                                  style={{ width: "100%" }}
                                  href={"/inscripcion/" + evento.hash}
                                >
                                  Inscribirse
                                </Button>
                              )}
                              {evento.link_alternativo != null && (
                                <Button
                                  variant="outline-primary"
                                  className="button-card"
                                  style={{ width: "100%" }}
                                  href={evento.link_alternativo}
                                  target="_blank"
                                >
                                  Inscribirse
                                </Button>
                              )}
                            </Card.Footer>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </Container>
                </Carousel.Item>
              ))}
            </Carousel>
          </Col>
          <Col
            xs={12}
            md={12}
            lg={4}
            style={{
              marginTop: "20px",
              display: "grid",
              placeItems: "center",
              marginBottom: "50px",
            }}
          >
            <img
              src={imgAgenda}
              style={{ width: "75%", borderRadius: "20px" }}
              alt={titulo}
            />
            <Button
              variant="outline-secondary"
              style={{ marginTop: "35px" }}
              onClick={() => {
                setModalAgenda(true);
              }}
            >
              VISUALIZAR {titulo}
            </Button>
          </Col>
        </Row>
      </Container>
      <ModalAgenda
        show={modalAgenda}
        onHide={() => {
          setModalAgenda(false);
          setCambios(cambios + 1);
        }}
        agenda={agendaImagen}
      />
    </Row>
  );
};

export default AgendaCarrusel;
