import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Breadcrumb,
  Form,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import "./style.css";
const helpers = require("../helpers/helpers");
const Catalogo = () => {
  const [contador] = useState(0);
  const { UniqueID } = useParams();
  const [catalogo, setCatalogo] = useState({});
  const [temas, setTemas] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const filteredTemas = temas.filter((tema) =>
    tema.nombre.toLowerCase().includes(searchTerm.toLowerCase())
  );
  useEffect(() => {
    helpers
      .getData("/api/catalogo-tema/view/" + UniqueID)
      .then((datos_catalogo) => {
        setCatalogo(datos_catalogo.data.salida[0]);
        helpers
          .getData("/api/temas/read/" + UniqueID)
          .then((datos_temas) => {
            console.log(datos_temas);
            setTemas(datos_temas.data.salida);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [contador]);

  return (
    <Container fluid>
      <Row
        style={{
          backgroundColor: "rgb(230, 230, 230)",
          padding: "2%",
          textAlign: "left",
          borderTop: "5px solid #003188",
          borderBottom: "5px solid #003188",
        }}
      >
        <Col xs={12} md={12} lg={2} style={{ padding: "1%" }}>
          <img
            src={catalogo.foto}
            style={{ width: "100%", borderRadius: "2em" }}
          />
        </Col>
        <Col xs={12} md={12} lg={10} style={{ padding: "1%" }}>
          <h1>
            <strong>EDUCACIÓN Y FORMACIÓN</strong>
          </h1>
          <h2>
            <strong>CATALOGO: </strong> {catalogo.Nombre}
          </h2>
          <p>{catalogo.descripcion}</p>
          <Container fluid>
            <Row className="mt-5">
              <Breadcrumb className="custom-breadcrumb">
                <Breadcrumb.Item className="breadcrumb-link" href="/home">
                  Inicio
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  className="breadcrumb-link"
                  href="/educacion-y-formacion"
                >
                  Educación y Formación
                </Breadcrumb.Item>
                <Breadcrumb.Item className="breadcrumb-active" active>
                  CATALOGO: {catalogo.Nombre}
                </Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Container>
        </Col>
      </Row>
      <Row className="mt-5" style={{ padding: "2%" }}>
        <Form className="mb-3">
          <Form.Group controlId="search">
            <Form.Control
              type="text"
              placeholder="Busca el tema por nombre"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Row>
      <Row>
        <Container fluid>
          <Row>
            {filteredTemas &&
              filteredTemas.map((tema, indice) => (
                <Col
                  xs={12}
                  md={6}
                  lg={4}
                  key={tema.UniqueID}
                  className="custom-col"
                >
                  <Card className="custom-card">
                    <Card.Img variant="top" src={tema.foto} />
                    <Card.Body className="d-flex flex-column">
                      <Card.Title>{tema.nombre}</Card.Title>
                      <div className="mt-auto">
                        <Button
                          className="custom-button"
                          href={"/educacion-y-formacion/tema/" + tema.UniqueID}
                        >
                          Más información
                        </Button>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
          </Row>
        </Container>
      </Row>
    </Container>
  );
};

export default Catalogo;
