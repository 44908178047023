import React, { useState, useEffect } from "react";
//import DEFAULT_URL from '../../../../../api/url';
import {
  Row,
  Alert,
  Button,
  Spinner,
  Popover,
  Col,
  OverlayTrigger,
  Form,
  Container,
  Accordion,
  ButtonToolbar,
  ButtonGroup,
  DropdownButton,
  InputGroup,
} from "react-bootstrap";
const popover = (
  <Popover id="popover-basic">
    <Popover.Header as="h3">¡Listo!</Popover.Header>
    <Popover.Body>Enlace copiado exitosamente.</Popover.Body>
  </Popover>
);
const DetalleEnlace = (props) => {
  const [contador, setContador] = useState(0);
  const [detalle, setDetalle] = useState("none");
  const [portada, setPortada] = useState("block");
  useEffect(() => {}, [contador]);

  const goBack = () => {
    setDetalle("none");
    setPortada("block");
    setContador(contador + 1);
  };
  const goToDetail = () => {
    setDetalle("block");
    setPortada("none");
    setContador(contador + 1);
  };
  return (
    <Row
      style={{
        marginTop: "15px",
        border: "0.5px dashed black",
        borderRadius: "15px",
        padding: "25px",
      }}
    >
      <Container fluid>
        <Row style={{ display: portada }}>
          <Container fluid>
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <Col xs={6} md={6} lg={6}>
                {props.enlace.TipoEnlace_UniqueID == 1 && (
                  <img
                    src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/zoom+(1).png"
                    style={{ width: "50%" }}
                    alt="logo"
                  />
                )}
                {props.enlace.TipoEnlace_UniqueID == 2 && (
                  <img
                    src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/facebook.png"
                    style={{ width: "50%" }}
                    alt="logo"
                  />
                )}
                {props.enlace.TipoEnlace_UniqueID == 3 && (
                  <img
                    src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/youtube.png"
                    style={{ width: "50%" }}
                    alt="logo"
                  />
                )}
                {props.enlace.TipoEnlace_UniqueID == 4 && (
                  <img
                    src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/Logo+virtual+ABG+2022-01.png"
                    style={{ width: "50%" }}
                    alt="logo"
                  />
                )}
              </Col>
              <Col xs={6} md={6} lg={6}>
                <h4>{props.enlace.Nombre}</h4>
              </Col>
            </Row>
            <Row style={{ marginBottom: "15px" }}>
              <OverlayTrigger
                trigger="click"
                placement="left"
                overlay={popover}
                rootClose
              >
                <Button
                  variant="outline-dark"
                  onClick={() => {
                    navigator.clipboard.writeText(props.enlace.url);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    className="bi bi-clipboard-check"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                    />
                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                  </svg>
                  &nbsp;&nbsp;&nbsp;&nbsp;Copiar enlace al portapapeles
                </Button>
              </OverlayTrigger>
            </Row>
            <Row>
              <Button variant="outline-info" onClick={goToDetail}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-info-square-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                </svg>
                &nbsp;&nbsp;&nbsp;&nbsp;Más información
              </Button>
            </Row>
          </Container>
        </Row>
        <Row style={{ display: detalle }}>
          <Container fluid>
            <Row>
              <h5>{props.enlace.Nombre}</h5>
              <Form.Control
                as="textarea"
                disabled
                placeholder="url"
                rows={3}
                style={{ resize: "none" }}
                value={props.enlace.url}
              />
            </Row>
            <Row>
              <h5>Comentario o instrucciones</h5>
              <Form.Control
                as="textarea"
                disabled
                placeholder="url"
                rows={3}
                style={{ resize: "none" }}
                value={props.enlace.comentario}
              />
            </Row>
            <Row style={{ marginTop: "15px" }}>
              <Button variant="outline-dark" onClick={goBack}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-90deg-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4z"
                  />
                </svg>
                &nbsp;&nbsp;&nbsp;&nbsp;Atrás
              </Button>
            </Row>
          </Container>
        </Row>
      </Container>
    </Row>
  );
};

export default DetalleEnlace;
