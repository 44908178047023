import React from 'react'
import { useParams } from 'react-router-dom'
import Inscripciones from './Inscripciones'
const InscripcionCortesia = () => {
    const { hash, hash_asesor } = useParams();
    return (
        <Inscripciones
            hash={hash}
            hash_asesor={hash_asesor}
            hash_descuento={null}
            lead = {null}
            cortesia = {true}
        />)
}

export default InscripcionCortesia