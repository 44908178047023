import React, { useEffect } from 'react'
import { Row, Col, Button, Card } from 'react-bootstrap';
import './Home.css'
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
const AboutRow = () => {
    const { ref, inView } = useInView({ threshold: 0.2 });
    const animation = useAnimation();
    const cardAnimation = useAnimation();
    const cardAnimation2 = useAnimation();
    useEffect(() => {
        if (inView) {
            animation.start({
                x: 0,
                transition: {
                    type: 'spring', duration: 1, bounce: 0.3
                }
            });
            cardAnimation.start({
                x: 0,
                transition: {
                    type: 'spring', duration: 1, bounce: 0.2
                }
            });
            cardAnimation2.start({
                x: 0,
                transition: {
                    type: 'spring', duration: 1, bounce: 0.2
                }
            });
        }
        if (!inView) {
            animation.start({ x: '-100vw' });
            cardAnimation.start({
                x: '-500vw'
            });
            cardAnimation2.start({
                x: '500vw'
            });
        }
    }, [inView, animation, cardAnimation, cardAnimation2]);
    return (
        <Row ref={ref} style={{ padding: '5%', backgroundImage: 'url(https://test-escuelabancaria.s3.us-east-2.amazonaws.com/Medios+General/bgsectionEBG.jpg)', backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%', color: 'white', display: 'flex', placeItems: 'center' }}>
            <Col xs={12} md={12} lg={12}>
                <Row style={{ marginBottom: '35px' }} >
                    <motion.h1 animate={animation}>ESCUELA BANCARIA DE GUATEMALA</motion.h1>
                    <h5>Buscamos proporcionar al Sector Bancario y Financiero, el Capital Humano competente para desarrollar con calidad sus funciones.</h5>
                </Row>
            </Col>
            <Col xs={12} md={12} lg={3} ></Col>
            <Col xs={12} md={12} lg={6} >
                <Card style={{ width: '100%', backgroundColor: 'transparent', boxShadow: 'rgba(255,255,255, 0.3) 0px 0px 0px 3px', marginBottom: '25px' }}>
                    <motion.div animate={cardAnimation}>
                        <Card.Img variant="top" src="https://test-escuelabancaria.s3.us-east-2.amazonaws.com/Medios+General/B-Logos.png" />
                        <Card.Body>
                            <Button variant="outline-light" href="quienes-somos">Conocer más</Button>
                        </Card.Body>
                    </motion.div>
                </Card>
            </Col>
        </Row>)
}

export default AboutRow