import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
const Agenda = (props) => {
    const [contador] = useState();
    const [agenda, setAgenda] = useState([]);
    useEffect(() => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/agenda-jornada/read/' + props.jornada.UniqueID, { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setAgenda(data.rows);
            });
    }, [contador]);
    return (
        <Container>
            {agenda.map((actual, index) => <Row key={'agenda-' + props.jornada.UniqueID + '-' + index} style={{ padding: '2%' }}>
                <Col xs={12} md={2} lg={2} style={{ marginTop: '25px', display: 'flex', placeItems: 'center', paddingTop: '2%', paddingBottom: '2%', paddingRight: '5%' }}>
                    <div style={{ width: '100%', objectFit: 'contain' }} >
                        <img src={actual.foto} style={{ width: '100%', objectFit: 'contain' }} />
                    </div>
                </Col>
                <Col xs={12} md={10} lg={10} style={{ textAlign: 'left', marginTop: '25px', paddingTop: '2%', paddingBottom: '2%', paddingLeft: '10%', borderLeft: '2px solid #003188' }}>
                    <h1><strong>{actual.concepto}</strong></h1>
                    <p style={{ fontSize: '2em' }}>{actual.hora_inicio}</p>
                    <p style={{ fontSize: '2em' }}>{actual.nombre}</p>
                    <p style={{ fontSize: '2em' }}>{actual.puesto}</p>
                    <p style={{ fontSize: '2em' }}>{actual.institucion}</p>
                    <p style={{ fontSize: '2em' }}>{actual.pais}</p>
                </Col>
            </Row>)
            }
        </Container >
    )
}

export default Agenda