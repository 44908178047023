import React from 'react';
import { Row, Col, Container } from "react-bootstrap";
import PasarelaForm from './PasarelaForm';
const Pasarela = () => {
    return (
        <Container fluid>
            <Row style={{ textAlign: 'center' }}>
                <Col xs={12} md={12} lg={6} style={{ padding: '5%' }}>
                    <img loading="lazy" src='https://escuela-bancaria.s3.us-east-2.amazonaws.com/Logotipos+Finales+ABG+2022-01.png' style={{ width: '100%' }} alt='logos' />
                </Col>
                <Col xs={12} md={12} lg={6} style={{ display: 'grid', alignItems: 'center', color: 'black', padding: '5%' }}>
                    <Row>
                        <h1>Pasarela de pagos</h1>
                        <h4>Por favor, llena el siguiente formulario para realizar tu pago</h4>
                    </Row>
                </Col>
            </Row>
            <Row>
                <PasarelaForm />
            </Row>
        </Container>)
}

export default Pasarela