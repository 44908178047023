import React from 'react'
import { Row, Col, Button } from 'react-bootstrap';
import './Home.css'
const ActivitiesRow = () => {
    return (
        <Row style={{ backgroundColor: '#cbcbcb', padding: '7%' }}>
            <Col xs={12} md={12} lg={6} style={{ color: '#003f9c', paddingTop: '6%', paddingBottom: '6%' }}>
                <Row>
                    <h3>Nuestras actividades</h3>
                    <p>Nuestra visión es ser la entidad líder en formación profesional integral del personal que labora en el Sector Bancario y financiero y otros sectores relacionados, a través de:</p>
                </Row>
                <Row>
                    <Col xs={12} md={12} lg={6} >
                        <Row style={{ padding: '5px' }}>
                            <Button variant='outline-secondary'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pen-fill" viewBox="0 0 16 16">
                                <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001z" />
                            </svg>&nbsp;&nbsp;&nbsp;&nbsp;Seminarios taller</Button>
                        </Row>
                    </Col>
                    <Col xs={12} md={12} lg={6} >
                        <Row style={{ padding: '5px' }}>
                            <Button variant='outline-secondary'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-mortarboard-fill" viewBox="0 0 16 16">
                                <path d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917l-7.5-3.5Z" />
                                <path d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466 4.176 9.032Z" />
                            </svg>&nbsp;&nbsp;&nbsp;&nbsp;Diplomados</Button>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12} lg={6} >
                        <Row style={{ padding: '5px' }}>
                            <Button variant='outline-secondary'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-fill" viewBox="0 0 16 16">
                                <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                            </svg>&nbsp;&nbsp;&nbsp;&nbsp;Cursos taller</Button>
                        </Row>
                    </Col>
                    <Col xs={12} md={12} lg={6} >
                        <Row style={{ padding: '5px' }}>
                            <Button variant='outline-secondary'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-megaphone-fill" viewBox="0 0 16 16">
                                <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-11zm-1 .724c-2.067.95-4.539 1.481-7 1.656v6.237a25.222 25.222 0 0 1 1.088.085c2.053.204 4.038.668 5.912 1.56V3.224zm-8 7.841V4.934c-.68.027-1.399.043-2.008.053A2.02 2.02 0 0 0 0 7v2c0 1.106.896 1.996 1.994 2.009a68.14 68.14 0 0 1 .496.008 64 64 0 0 1 1.51.048zm1.39 1.081c.285.021.569.047.85.078l.253 1.69a1 1 0 0 1-.983 1.187h-.548a1 1 0 0 1-.916-.599l-1.314-2.48a65.81 65.81 0 0 1 1.692.064c.327.017.65.037.966.06z" />
                            </svg>&nbsp;&nbsp;&nbsp;&nbsp;Foros</Button>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12} lg={6} >
                        <Row style={{ padding: '5px' }}>
                            <Button variant='outline-secondary'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-people-fill" viewBox="0 0 16 16">
                                <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                <path fillRule="evenodd" d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z" />
                                <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
                            </svg>&nbsp;&nbsp;&nbsp;&nbsp;Congresos</Button>
                        </Row>
                    </Col>
                    <Col xs={12} md={12} lg={6} >
                        <Row style={{ padding: '5px' }}>
                            <Button variant='outline-secondary'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-mortarboard" viewBox="0 0 16 16">
                                <path d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917l-7.5-3.5ZM8 8.46 1.758 5.965 8 3.052l6.242 2.913L8 8.46Z" />
                                <path d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466 4.176 9.032Zm-.068 1.873.22-.748 3.496 1.311a.5.5 0 0 0 .352 0l3.496-1.311.22.748L8 12.46l-3.892-1.556Z" />
                            </svg>&nbsp;&nbsp;&nbsp;&nbsp;Certificaciones</Button>
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col xs={12} md={12} lg={6} style={{ marginBottom: '25px' }}>
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/8WxqpJOrdKU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Col>
        </Row>
    )
}

export default ActivitiesRow

/*    background-color: transparent;
    background-image: linear-gradient(180deg, var( --e-global-color-primary ) 0%, #011F54 100%);
} */