import React, { useEffect, useState } from "react";
import { Container, Row, Col, Table, Button, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Jornadas from "./Jornadas";
import Patrocinadores from "./Patrocinadores";
import Temario from "./Temario";
import "./style.css";
const ValidarCorreo = () => {
  const { hash } = useParams();
  const [contador] = useState();
  const [evento, setEvento] = useState({});
  const [tarifario, setTarifario] = useState([]);
  const [validated, setValidated] = useState(false);
  const [cambios, setCambios] = useState(0);
  const [cargando, setCargando] = useState("none");
  const [exito, setExito] = useState("none");
  const [error, setError] = useState("none");
  const [mensaje, setMensaje] = useState("");
  const [idInscripcion, setIdInscripcion] = useState("");
  const [imgAgenda, setImgAgenda] = useState("");
  const [titulo, setTitulo] = useState("");
  const [jornadas, setJornadas] = useState([]);
  const [patrocinadores, setPatrocinadores] = useState([]);
  const [ejes, setEjes] = useState([]);
  const [email, setEmail] = useState("");
  const [sesiones, setSesiones] = useState([]);
  useEffect(() => {
    fetch(
      process.env.REACT_APP_DEFAULT_URL + "/api/read/evento/landing/" + hash,
      { cache: "no-cache" }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.rows[0].disponible == "0") {
          window.location.replace("/unauthorized");
        } else {
          let idEvt = data.rows[0].UniqueID;
          setEvento(data.rows[0]);
          fetch(
            process.env.REACT_APP_DEFAULT_URL +
              "/api/read/evento/tarifas/" +
              idEvt,
            { cache: "no-cache" }
          )
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              setTarifario(data.salida);
              fetch(
                process.env.REACT_APP_DEFAULT_URL +
                  "/api/jornada/read/" +
                  idEvt,
                { cache: "no-cache" }
              )
                .then((res) => {
                  return res.json();
                })
                .then((data) => {
                  setJornadas(data.rows);
                  fetch(
                    process.env.REACT_APP_DEFAULT_URL +
                      "/api/temario/read/" +
                      idEvt,
                    { cache: "no-cache" }
                  )
                    .then((res) => {
                      return res.json();
                    })
                    .then((data) => {
                      setEjes(data.rows);
                      fetch(
                        process.env.REACT_APP_DEFAULT_URL +
                          "/api/sesion/check/" +
                          idEvt,
                        { cache: "no-cache" }
                      )
                        .then((response) => {
                          return response.json();
                        })
                        .then((datos) => {
                          setSesiones(datos.rows);
                          fetch(
                            process.env.REACT_APP_DEFAULT_URL +
                              "/api/read/patrocinador-for-page/" +
                              idEvt,
                            { cache: "no-cache" }
                          )
                            .then((res) => {
                              return res.json();
                            })
                            .then((data) => {
                              setPatrocinadores(data.rows);
                            });
                        });
                    });
                });
            });
        }
      });
    fetch(process.env.REACT_APP_DEFAULT_URL + "/api/agenda/info", {
      cache: "no-cache",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setImgAgenda(data.imagen);
        setTitulo(data.titulo);
      });
  }, [contador]);
  useEffect(() => {}, [cambios]);
  const onSubmit = (evt) => {
    const form = evt.currentTarget;
    if (form.checkValidity() === false) {
      evt.preventDefault();
      evt.stopPropagation();
    } else {
      evt.preventDefault();
      cargar();
      const body_validar_correos = { participantes: [{ Correo: email }] };
      const requestOptionsValidarEmail = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body_validar_correos),
      };
      fetch(
        process.env.REACT_APP_DEFAULT_URL +
          "/api/read/detalle_inscripcion/search/email/" +
          evento.UniqueID,
        requestOptionsValidarEmail
      )
        .then((resValidarCorreos) => {
          return resValidarCorreos.json();
        })
        .then((validarCorreos) => {
          if (validarCorreos.salida.length == 0) {
            window.location.replace(
              process.env.REACT_APP_SELF_URL + "/inscripcion/" + hash
            );
          } else {
            if (sesiones.length == 0) {
              window.location.replace(
                process.env.REACT_APP_SELF_URL +
                  "/mi-gafete-electronico/" +
                  validarCorreos.salida[0].hash
              );
            } else {
              const info = {
                sesion: sesiones[0].sesion.UniqueID,
                plataforma: "ebg.edu.gt",
                hash: validarCorreos.salida[0].hash,
                evento_UniqueID: evento.UniqueID,
              };
              const requestOptions = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(info),
              };
              fetch(
                process.env.REACT_APP_DEFAULT_URL +
                  "/api/create/evento/marcar/asistencia",
                requestOptions
              )
                .then((res) => {
                  return res.json();
                })
                .then((data) => {
                  window.location.replace(sesiones[0].enlaces[0].url);
                });
            }
          }
        });
    }
    setValidated(true);
    setCambios(cambios + 1);
  };

  const onChange = (evt) => {
    if (evt.target.name == "email") {
      setEmail(evt.target.value);
    }
    setCambios(cambios + 1);
  };
  const cargar = () => {
    setCargando("block");
    setExito("none");
    setError("none");
    setMensaje("Procesando información...");
    setCambios(cambios + 1);
  };
  const operacionExito = (log) => {
    setCargando("none");
    setExito("block");
    setError("none");
    setMensaje(log);
    setCambios(cambios + 1);
  };
  const operacionError = (log) => {
    setCargando("none");
    setExito("none");
    setError("block");
    setMensaje(log);
    setCambios(cambios + 1);
  };
  const retry = () => {
    setCargando("none");
    setExito("none");
    setError("none");
    setMensaje("");
    setCambios(cambios + 1);
  };
  const print = () => {
    if (idInscripcion != "" && idInscripcion != null) {
      cargar();
      fetch(
        process.env.REACT_APP_DEFAULT_URL + "/api/print/sheet/" + idInscripcion,
        { cache: "no-cache" }
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          // tmp[indice].enlacePDF = data.enlace_S3;
          operacionExito("PDF generado exitosamente");
          window.open(data.enlace_S3, "_blank");
        });
    } else {
      operacionError("Ocurrió un error al generar PDF");
    }
  };
  const goBottom = () => {
    let footer = document.getElementById("validacion-email");
    footer.scrollIntoView();
  };
  return (
    <Container fluid>
      <div id="div-boton-inscribirse">
        <Container fluid>
          <Row>
            <Button variant="dark" onClick={goBottom}>
              Ingresar e-mail para validacion
            </Button>
          </Row>
        </Container>
      </div>
      <Row
        style={{
          backgroundColor: "rgb(230, 230, 230)",
          padding: "2%",
          textAlign: "left",
          borderTop: "5px solid #003188",
          borderBottom: "5px solid #003188",
        }}
        id="header-evt"
      >
        <Col xs={12} md={12} lg={2} style={{ padding: "1%" }}>
          <img src={imgAgenda} style={{ width: "100%", borderRadius: "2em" }} />
        </Col>
        <Col xs={12} md={12} lg={10} style={{ padding: "1%" }}>
          <h1>
            <strong>{evento.tipo}</strong>
          </h1>
          <h3>
            <strong>{titulo}</strong>
          </h3>
        </Col>
      </Row>
      <Row style={{ padding: "2%" }} id="info-evt">
        <Container>
          <Row>
            <Col xs={12} md={12} lg={6}>
              <img src={evento.flier} alt="img-evt" style={{ width: "75%" }} />
            </Col>
            <Col
              xs={12}
              md={12}
              lg={6}
              style={{
                textAlign: "right",
                display: "grid",
                placeItems: "center",
              }}
            >
              <Container fluid>
                <Row>
                  <h2>
                    <strong>{evento.Nombre}</strong>
                  </h2>
                  <p>
                    <strong>Inicia: </strong>
                    {evento.Fecha_Inicio}
                  </p>
                  <p>
                    <strong>Finaliza: </strong>
                    {evento.Fecha_Fin}
                  </p>
                  <p>
                    <strong>Días a impartir: </strong>
                    {evento.dias}
                  </p>
                  <p>
                    <strong>Hora: </strong>
                    {evento.Hora}
                  </p>
                  <p>
                    <strong>Duración: </strong>
                    {evento.duracion}
                  </p>
                  <p>
                    <strong>No. de sesiones: </strong>
                    {evento.sesiones}
                  </p>
                </Row>
                <Row id="validacion-email">
                  <Container fluid>
                    <Row
                      style={{
                        padding: "2%",
                        textAlign: "center",
                        borderTop: "5px solid #003188",
                        borderBottom: "5px solid #003188",
                        backgroundColor: "rgb(230, 230, 230)",
                      }}
                    >
                      <h1>
                        <strong>VALIDACIÓN DE EMAIL</strong>
                      </h1>
                    </Row>
                    <Row>
                      <Form
                        noValidate
                        validated={validated}
                        onSubmit={onSubmit}
                      >
                        <Container fluid>
                          <Row style={{ padding: "2%" }}>
                            <Form.Group controlId="email">
                              <Form.Label>Ingresa tu email</Form.Label>
                              <Form.Control
                                type="email"
                                placeholder="Por favor, ingresa tu email"
                                name="email"
                                onChange={onChange}
                                style={{ resize: "none" }}
                                value={email}
                                disabled={
                                  cargando == "block" ||
                                  exito == "block" ||
                                  error == "block"
                                }
                                required
                              />
                              <Form.Control.Feedback>Ok</Form.Control.Feedback>
                              <Form.Control.Feedback type="invalid">
                                Por favor, ingresa tu email
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Row>
                          <Row style={{ padding: "2%" }}>
                            <Button
                              type="submit"
                              variant="outline-primary"
                              disabled={
                                cargando == "block" ||
                                exito == "block" ||
                                error == "block"
                              }
                            >
                              Validar e-mail
                            </Button>
                          </Row>
                        </Container>
                      </Form>
                    </Row>
                  </Container>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </Row>
      {evento.video_promo != null && (
        <Row
          style={{
            padding: "2%",
            textAlign: "left",
            borderTop: "5px solid #003188",
          }}
          id="video"
        >
          <section>
            <video autoPlay muted loop style={{ width: "100%" }}>
              <source src={evento.video_promo} type="video/mp4" />
            </video>
          </section>
        </Row>
      )}
      <Row id="jornadas">
        <Jornadas jornadas={jornadas} />
      </Row>
      <Row
        style={{
          textAlign: "center",
          padding: "2%",
          borderTop: "5px solid #003188",
          borderBottom: "5px solid #003188",
          backgroundColor: "rgb(230, 230, 230)",
        }}
        id="tarifas-titulo"
      >
        <h1>
          <strong>TARIFAS DE PARTICIPACIÓN</strong>
        </h1>
      </Row>
      <Row
        style={{
          paddingTop: "2%",
          paddingBottom: "2%",
          paddingLeft: "15%",
          paddingRight: "15%",
        }}
        id="tarifario"
      >
        {tarifario.length == 0 && (
          <Table striped bordered hover variant="dark">
            <thead>
              <tr>
                <th>Perfil</th>
                <th>Costo</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Agremiados</td>
                <td>{evento.precio_agremiados}</td>
              </tr>
              <tr>
                <td>No Agremiados</td>
                <td>{evento.precio_no_agremiados}</td>
              </tr>
            </tbody>
          </Table>
        )}
        {tarifario.length > 0 && (
          <Container>
            <Row>
              {tarifario.map((tarifa, indice) => (
                <Col xs={12} md={12} lg={12} key={indice}>
                  <Row>
                    <h5>
                      <strong>{tarifa.tarifa}</strong>
                    </h5>
                    <Table responsive bordered hover variant="dark">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Participantes</th>
                          <th>Precio</th>
                          <th>Beneficio</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tarifa.costos.map((costo, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{costo.descripcion}</td>
                            <td>{costo.precio}</td>
                            <td style={{ fontStyle: "oblique" }}>
                              {costo.adicional}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Row>
                </Col>
              ))}
            </Row>
          </Container>
        )}
      </Row>
      {evento.planos != null && (
        <Row>
          <Container fluid>
            <Row
              style={{
                padding: "2%",
                textAlign: "center",
                borderTop: "5px solid #003188",
                borderBottom: "5px solid #003188",
                backgroundColor: "rgb(230, 230, 230)",
              }}
            >
              <h1>
                <strong>PLANOS</strong>
              </h1>
            </Row>
            <Row style={{ padding: "5%" }}>
              <section>
                <img
                  src={evento.planos}
                  style={{ width: "75%" }}
                  alt="planos"
                  loading="lazy"
                />
              </section>
            </Row>
          </Container>
        </Row>
      )}
      {ejes.length > 0 && (
        <Row>
          <Container fluid>
            <Row
              style={{
                padding: "2%",
                textAlign: "center",
                borderTop: "5px solid #003188",
                borderBottom: "5px solid #003188",
                backgroundColor: "rgb(230, 230, 230)",
              }}
            >
              <h1>
                <strong>TEMARIO PRELIMINAR</strong>
              </h1>
            </Row>
            {ejes.map((actual, index) => (
              <Row style={{ padding: "5%" }} key={"temario-" + index}>
                <Col xs={12} md={12} lg={4} style={{ textAlign: "right" }}>
                  <p>
                    <strong>{actual.descripcion}</strong>
                  </p>
                </Col>
                <Col
                  xs={12}
                  md={12}
                  lg={8}
                  style={{ borderLeft: "5px solid #003188", textAlign: "left" }}
                >
                  <Temario eje={actual.UniqueID} />
                </Col>
              </Row>
            ))}
          </Container>
        </Row>
      )}
      {patrocinadores.length > 0 && (
        <Row id="patrocinadores">
          <Patrocinadores patrocinadores={patrocinadores} />
        </Row>
      )}
    </Container>
  );
};

export default ValidarCorreo;
