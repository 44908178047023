import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import DetalleEnlace from "./DetalleEnlace";
const Sesion = (props) => {
  const [contador] = useState(0);
  const [enlaces, setEnlaces] = useState([]);
  useEffect(() => {
    fetch(
      process.env.REACT_APP_DEFAULT_URL +
        "/api/read/enlace/" +
        props.sesion.UniqueID,
      { cache: "no-cache" }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setEnlaces(data.rows);
      });
  }, [contador]);
  return (
    <Row style={{ textAlign: "left", padding: "5%" }}>
      <Col xs={12} md={12} lg={6} style={{ textAlign: "left", padding: "2%" }}>
        <h2>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            className="bi bi-hourglass-split"
            viewBox="0 0 16 16"
          >
            <path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z" />
          </svg>
          &nbsp;&nbsp;&nbsp;&nbsp;Horario
        </h2>
        <p>
          <strong>Inicio: </strong>
          {props.sesion.fechaInicio}
        </p>
        <p>
          <strong>Fin: </strong>
          {props.sesion.fechaFin}
        </p>
      </Col>
      <Col xs={12} md={12} lg={6} style={{ textAlign: "left", padding: "2%" }}>
        <h2>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            className="bi bi-laptop"
            viewBox="0 0 16 16"
          >
            <path d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5h11zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2h-11zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5z" />
          </svg>&nbsp;&nbsp;&nbsp;&nbsp;
          Accesos virtuales
        </h2>
        {enlaces.map((actual, index) => (
          <DetalleEnlace enlace={actual} />
        ))}
      </Col>
    </Row>
  );
};

export default Sesion;
