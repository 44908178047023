import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";
import Patrocinadores from "./Patrocinadores";
import Sesion from "./Sesion";
const Gafete = () => {
  const { hash } = useParams();
  const [contador, setContador] = useState(0);
  const [cambios, setCambios] = useState(0);
  const [gafete, setGafete] = useState({});
  const [validated, setValidated] = useState(false);
  const [foto, setFoto] = useState(null);
  const [cargando, setCargando] = useState(false);
  const [evento, setEvento] = useState([]);
  const [patrocinadores, setPatrocinadores] = useState([]);
  const [sesiones, setSesiones] = useState([]);

  useEffect(() => {
    fetch(process.env.REACT_APP_DEFAULT_URL + "/api/read/gafete/" + hash, {
      cache: "no-cache",
      credentials: "include",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setGafete(data.salida[0]);
        fetch(
          process.env.REACT_APP_DEFAULT_URL +
            "/api/read-stand/parocinador/" +
            data.salida[0].evento +
            "/" +
            hash,
          { cache: "no-cache" }
        )
          .then((res) => {
            return res.json();
          })
          .then((data_patrocinadores) => {
            let tmp = [];
            data_patrocinadores.rows.map((actual, indice) => {
              const index_finder_2 = (curr) =>
                curr.patrocinador == actual.UniqueID;
              if (data_patrocinadores.stands.findIndex(index_finder_2) >= 0) {
                actual.visitado = true;
              } else {
                actual.visitado = false;
              }
              if (tmp.length == 0) {
                tmp.push({ tipo: actual.tipo, patrocinadores: [actual] });
              } else {
                const index_finder = (curr) => curr.tipo == actual.tipo;
                //let email_asesor = asesores[asesores.findIndex(index_finder)].email;
                if (tmp.findIndex(index_finder) >= 0) {
                  tmp[tmp.findIndex(index_finder)].patrocinadores.push(actual);
                } else {
                  tmp.push({ tipo: actual.tipo, patrocinadores: [actual] });
                }
              }
              if (indice == data_patrocinadores.rows.length - 1) {
                setPatrocinadores(tmp);
                fetch(
                  process.env.REACT_APP_DEFAULT_URL +
                    "/api/sesion/read/" +
                    data.salida[0].evento,
                  { cache: "no-cache" }
                )
                  .then((response) => {
                    return response.json();
                  })
                  .then((datos) => {
                    setSesiones(datos.rows);
                  });
              }
            });
          });
      });
  }, [contador]);

  useEffect(() => {}, [cambios]);
  const onChange = (evt) => {
    setFoto(evt.target.files[0]);
    setCambios(cambios + 1);
  };
  const onSubmit = (evt) => {
    const form = evt.currentTarget;
    if (form.checkValidity() === false) {
      evt.preventDefault();
      evt.stopPropagation();
    } else {
      evt.preventDefault();
      const data = new FormData();
      data.append("file", foto);
      setCargando(true);
      axios
        .post(
          process.env.REACT_APP_DEFAULT_URL + "/api/files/upload/s3",
          data,
          {}
        )
        .then((res) => {
          if (res.data.codigo == 200) {
            const info = { foto: res.data.resultado.Location, hash: hash };
            const requestOptions = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(info),
            };
            fetch(
              process.env.REACT_APP_DEFAULT_URL + "/api/update/gafete",
              requestOptions
            )
              .then((res) => {
                return res.json();
              })
              .then((data) => {
                if (data.codigo == 200) {
                  window.location.reload();
                } else {
                }
                setCambios(cambios + 1);
              });
          }
        });
    }
    setValidated(true);
  };
  const goBottom = () => {
    let footer = document.getElementById("accesos");
    footer.scrollIntoView();
  };
  return (
    <Container fluid>
      {sesiones.length > 0 && (
        <div id="div-boton-inscribirse">
          <Container fluid>
            <Row>
              <Button variant="dark" onClick={goBottom}>
                Accesos a evento
              </Button>
            </Row>
          </Container>
        </div>
      )}
      <Row
        style={{
          backgroundColor: "rgb(230, 230, 230)",
          padding: "2%",
          textAlign: "left",
          borderTop: "5px solid #003188",
          borderBottom: "5px solid #003188",
        }}
        id="header-evt"
      >
        <Col xs={12} md={12} lg={2} style={{ padding: "1%" }}>
          {gafete.foto == null && (
            <img
              style={{ width: "100%", borderRadius: "2em" }}
              src="https://ik.imagekit.io/p5ethkcnzwh/person-icon_B5aNsHlZl.png?ik-sdk-version=javascript-1.4.3&updatedAt=1659026017200"
            />
          )}
          {gafete.foto != null && (
            <img
              src={gafete.foto}
              style={{ width: "100%", borderRadius: "2em" }}
            />
          )}
        </Col>
        <Col xs={12} md={12} lg={10} style={{ padding: "1%" }}>
          <h1>
            <strong>{gafete.Nombres + " " + gafete.Apellidos}</strong>
          </h1>
          <p>
            <strong>Institución: </strong>
            {gafete.empresa}
          </p>
          <p>
            <strong>Tel: </strong>
            {gafete.Telefono}
          </p>
          <p>
            <strong>Cel: </strong>
            {gafete.celular}
          </p>
          <p>
            <strong>Email: </strong>
            {gafete.Correo}
          </p>
          <p>
            <strong>País: </strong>
            {gafete.pais}
          </p>
        </Col>
      </Row>
      <Row style={{ padding: "2%" }}>
        <Col xs={12} md={12} lg={6}>
          <Container fluid>
            <Row>
              <h3>Personalizar foto de gafete electrónico</h3>
              <Form noValidate validated={validated} onSubmit={onSubmit}>
                <Row style={{ marginTop: "15px" }}>
                  <Form.Group controlId="foto">
                    <Form.Label>Selecciona una foto</Form.Label>
                    <Form.Control
                      required
                      type="file"
                      placeholder="Selecciona una foto"
                      onChange={onChange}
                      accept="image/*"
                      disabled={cargando}
                    />
                    <Form.Control.Feedback>Ok!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Por favor selecciona una foto
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row style={{ marginTop: "15px" }}>
                  <Button variant="outline-dark" type="submit">
                    Subir foto
                  </Button>
                </Row>
              </Form>
            </Row>
          </Container>
        </Col>
        <Col xs={12} md={12} lg={6}>
          <img style={{ width: "50%" }} src={gafete.promocional} />
        </Col>
      </Row>
      <Row>
        {patrocinadores.length > 0 && (
          <Row id="patrocinadores">
            <Patrocinadores
              patrocinadores={patrocinadores}
              participante={hash}
            />
          </Row>
        )}
      </Row>
      {sesiones.length > 0 && (
        <Row id="accesos">
          <Container fluid>
            <Row
              style={{
                padding: "2%",
                textAlign: "center",
                borderTop: "5px solid #003188",
                borderBottom: "5px solid #003188",
                backgroundColor: "rgb(230, 230, 230)",
              }}
            >
              <h1>
                <strong>ACCESOS A EVENTO VIRTUAL</strong>
              </h1>
            </Row>
            {sesiones.map((actual, index) => (
              <Sesion key={"sesion-" + index} sesion={actual} indice={index} />
            ))}
          </Container>
        </Row>
      )}
    </Container>
  );
};

export default Gafete;
