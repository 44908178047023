import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Accordion,
  Card,
  ListGroup,
} from "react-bootstrap";
import "./Certificaciones.css";
import Objetivos from "./objetivos";
import Enfoque from "./enfoque";
const Certificaciones = () => {
  const [contador, setContador] = useState(0);
  const [objetivos, setObjetivos] = useState(false);
  const [enfoque, setEnfoque] = useState(false);
  useEffect(() => {}, [contador]);
  return (
    <Container fluid>
      <Row style={{ padding: "3%" }}>
        <Col xs={12} md={12} lg={6} style={{ marginTop: "3px" }}>
          <img
            src="https://test-escuelabancaria.s3.us-east-2.amazonaws.com/Asset-1.svg"
            alt="logos"
          />
        </Col>
        <Col
          xs={12}
          md={12}
          lg={6}
          style={{
            display: "grid",
            alignItems: "center",
            color: "#003188",
            borderRadius: "35px",
            marginTop: "3px",
          }}
        >
          <h1>Certificaciones</h1>
        </Col>
      </Row>
      <Row style={{ marginBottom: "50px" }}>
        <Col xs={12} md={12} lg={3}>
          <img
            src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/WhatsApp+Image+2024-08-24+at+8.06.43+AM.jpeg"
            alt="Second slide"
            style={{ width: "100%", borderRadius: "10%" }}
          />
        </Col>
        <Col xs={12} md={12} lg={3}>
          <img
            src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/WhatsApp+Image+2024-08-24+at+8.06.43+AM+(1).jpeg"
            alt="First slide"
            style={{ width: "100%", borderRadius: "10%" }}
          />
        </Col>
        <Col xs={12} md={12} lg={3}>
          <img
            src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/WhatsApp+Image+2024-08-24+at+8.06.43+AM+(3).jpeg"
            alt="First slide"
            style={{ width: "100%", borderRadius: "10%" }}
          />
        </Col>
        <Col xs={12} md={12} lg={3}>
          <img
            src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/WhatsApp+Image+2024-08-24+at+8.06.43+AM+(2).jpeg"
            alt="First slide"
            style={{ width: "100%", borderRadius: "10%" }}
          />
        </Col>
      </Row>
      <Row className="more">
        <Col xs={12} md={6} lg={6} style={{ padding: "2%" }}>
          <img
            src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/documento.png"
            style={{ width: "25%", marginBottom: "15px" }}
            alt="img-about"
          />
          <h1 style={{ color: "white", textAlign: "left" }}>BRIEF AMLAC-FIBA Y ABG</h1>
          
          <Button
          href='https://escuela-bancaria.s3.us-east-2.amazonaws.com/BRIEF+AMLAC-+FIBA+y+ABG+(1)+(1).pdf'
          target="_blank"
            variant="outline-light"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-check-circle-fill"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
            </svg>
            &nbsp;&nbsp;&nbsp;CLICK PARA LEER
          </Button>
        </Col>
        <Col xs={12} md={6} lg={6} style={{ padding: "2%" }}>
          <img
            src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/documento.png"
            style={{ width: "25%", marginBottom: "15px" }}
            alt="img-about"
          />
          <h1 style={{ color: "white", textAlign: "left" }}>
            ESQUEMA 1 BROCHURE CERTIFICACIÓN CON WCA
          </h1>
        
          <Button
            href='https://escuela-bancaria.s3.us-east-2.amazonaws.com/Esquema+1+Brochure+Certificacion+con+WCA+copia+(1).pdf'
            target="_blank"
            variant="outline-light"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-check-circle-fill"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
            </svg>
            &nbsp;&nbsp;&nbsp;CLICK PARA LEER
          </Button>
        </Col>
        <Col xs={12} md={6} lg={6} style={{ padding: "2%" }}>
          <img
            src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/documento.png"
            style={{ width: "25%", marginBottom: "15px" }}
            alt="img-about"
          />
          <h1 style={{ color: "white", textAlign: "left" }}>
            ESQUEMA 2 BROCHURE CERTIFICACIÓN CON WCA
          </h1>
       
          <Button
           href='https://escuela-bancaria.s3.us-east-2.amazonaws.com/Esquema+2+Brochure+Certificacion+con+WCA+(1).pdf'
           target="_blank"
            variant="outline-light"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-check-circle-fill"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
            </svg>
            &nbsp;&nbsp;&nbsp;CLICK PARA LEER
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Certificaciones;
