import React, { useState, useEffect } from 'react';
import {  Row, Button, Col, Form, Container } from "react-bootstrap";
import RowDetail from './rowDetail';
const Calendario = () => {
    const [contador, setContador] = useState(0);
    const [eventos, setEventos] = useState([]);
    const [anioSelected, setAnioSelected] = useState('');
    const [monthSelected, setMonthSelected] = useState('');
    const [seleccionMes, setSeleccionMes] = useState([]);
    const [selectedInfo, setSelectedInfo] = useState([]);
    useEffect(() => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/by-year/all', { cache: "no-cache", credentials: 'include' })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setEventos(data.eventos);
            });
    }, [contador]);
    const onChange = (evt) => {
        if (evt.target.name == 'seleccion-anio') {
            setAnioSelected(evt.target.value);
            if (eventos[evt.target.value] != null) {
                setSeleccionMes(eventos[evt.target.value].meses);
            } else {
                setSeleccionMes([]);
            }
        } else {
            setMonthSelected(evt.target.value);
        }
        setSelectedInfo([]);
        setContador(contador + 1);
    }
    const search = () => {
        setSelectedInfo([]);
        setContador(contador + 1);
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/by-year/by-month/' + eventos[anioSelected].year + '/' + seleccionMes[monthSelected].mes + '/All', { cache: "no-cache", credentials: 'include' })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                let agnd = [];
                let actual = [];
                data.rows.map((current, index) => {
                    if (((index + 1) % 4 === 0 && index > 0) || index === (data.rows.length - 1)) {
                        actual.push(current);
                        agnd.push(actual);
                        actual = [];
                        if (index === (data.rows.length - 1)) {                            
                            setSelectedInfo(agnd);
                            setContador(contador+1);
                        }
                    } else {
                        actual.push(current);
                    }
                    return true;
                });
            });
    }
    const onSubmit = (evt) => {
        evt.preventDefault();
        setSelectedInfo([]);
        setContador(contador + 1);
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/by-year/by-month/' + eventos[anioSelected].year + '/' + seleccionMes[monthSelected].mes + '/All', { cache: "no-cache", credentials: 'include' })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                let agnd = [];
                let actual = [];
                data.rows.map((current, index) => {
                    if (((index + 1) % 4 === 0 && index > 0) || index === (data.rows.length - 1)) {
                        actual.push(current);
                        agnd.push(actual);
                        actual = [];
                        if (index === (data.rows.length - 1)) {
                            setSelectedInfo(agnd);
                            setContador(contador+1);
                        }
                    } else {
                        actual.push(current);
                    }
                    return true;
                });
            });
    }
    return (
        <Container fluid>
            <Row style={{ textAlign: 'center' }}>
                <Col xs={12} md={12} lg={6} style={{ padding: '5%' }}>
                    <img loading="lazy" src='https://escuela-bancaria.s3.us-east-2.amazonaws.com/Logotipos+Finales+ABG+2022-01.png' style={{ width: '100%' }} alt='logos' />
                </Col>
                <Col xs={12} md={12} lg={6} style={{ display: 'grid', alignItems: 'center', color: 'black', padding: '5%' }}>
                    <Row>
                        <h1>Calendario de eventos</h1>
                        <h4>Por favor, selecciona un periodo</h4>
                    </Row>
                    <Row>
                        <Form onSubmit={onSubmit}>
                            <Container fluid>
                                <Row>
                                    <Form.Group className="mb-3">
                                        <Form.Label style={{ color: '#1e3d52', fontWeight: 'bold' }}>Selecciona un año</Form.Label>
                                        <Form.Control as="select" required name='seleccion-anio' onChange={onChange} value={anioSelected}>
                                            <option value="" key="0">Seleccione el año que desee consultar</option>
                                            {eventos.map((actual, indice) => <option key={indice + 1} value={indice}>{actual.year}</option>)}
                                        </Form.Control>
                                    </Form.Group>
                                </Row>
                                {seleccionMes != null && seleccionMes.length > 0 && <Row>
                                    <Form.Group className="mb-3">
                                        <Form.Label style={{ color: '#1e3d52', fontWeight: 'bold' }}>Selecciona un mes</Form.Label>
                                        <Form.Control as="select" required name='seleccion-mes' onChange={onChange} value={monthSelected}>
                                            <option value="" key="0">Seleccione el mes que desee consultar</option>
                                            {seleccionMes.map((actual, indice) => <option key={indice} value={indice}>{actual.eventos > 1 && actual.mes + ' ( ' + actual.eventos + ' eventos )'}{actual.eventos === 1 && actual.mes + ' ( ' + actual.eventos + ' evento )'} </option>)}
                                        </Form.Control>
                                    </Form.Group>
                                </Row>}
                                <Row>
                                    <Button variant='outline-dark' type='submit'>Consultar</Button>
                                </Row>
                            </Container>
                        </Form>
                    </Row>
                </Col>
            </Row>
            {selectedInfo.length > 0 && <Row>
                <Container>
                    <Row style={{ marginBottom: '35px', marginTop:'35px' }}>
                        <h1> {'Agenda ' + seleccionMes[monthSelected].mes + ' ' + eventos[anioSelected].year}</h1>
                    </Row>
                    {selectedInfo.map((actual, index) => <RowDetail eventos={actual} />)}
                </Container>
            </Row>}
        </Container>
    )
}

export default Calendario